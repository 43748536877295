@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';


.StudentFeesInfo_main_contianer {
    padding: 1vw 1vw 1vw 1vw;
}

.StudentFeesInfo_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 2vw 0;
    padding: 0.3vw 0.5vw;

}

.StudentFeesInfo_header_container h1 {
    font-size: 1.5vw;
    font-weight: 500;
    width: 90%;
    text-align: center;
}

.StudentFeesInfo_header_container button {
    border-radius: 50vw;
    padding: 0.3vw 0.5vw;
    border: none;
    font-weight: bold;
    cursor: pointer;
}

.StudentFeesInfo_Details_container {
    padding: 0 1vw;
    border-bottom: 0.2vw solid rgba(0, 0, 0, 0.4);
}

.first_Row {
    display: flex;
    align-items: center;
    width: 100%;
}

.firstName {
    width: 33.33%;
}

.firstName h1 {
    font-size: 1.3vw;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1vw;
}

////////////////////////////////////////////////////////

.pay_Installment_main_container {
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 1vw;
    position: relative;
    padding: 1.5vw;
    margin: 3vw 0;
}


.pay_Installment_title_container {
    background-color: var(--secondary-background-color);
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    padding: 0 0.3vw;
}

.pay_Installment_title_container h1 {
    font-size: 1.5vw;
    font-weight: 500;
}

.pay_Installment_content_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.pay_Installment_left_container {
    width: 50%;
    padding: 0 1.5vw 0 0;
}

.pay_Installment_left_container>div {
    margin-bottom: 0.8vw;
}

.pay_Installment_right_container {
    width: 50%;
    padding: 0 0 0 1.5vw;


}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6vw;
    z-index: 99999;
    overflow-y: auto;
}

.modal-content {
    background-color: var(--secondary-text-color);
    border-radius: 0.7vw;
    overflow-y: auto;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

.mode_of_payment {
    margin-top: 0.8vw;
}

@media only screen and (max-width:$mobileMaxWidth) {
    .StudentFeesInfo_main_contianer {
        padding: 1vw 1vw 1vw 1vw;
    }

    .StudentFeesInfo_header_container {
        display: flex;
        font-size: 3.5vw;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 0 2vw 0;
        padding: 0.3vw 0.5vw;

    }

    .StudentFeesInfo_header_container h1 {
        font-size: 4vw;
        font-weight: 500;
        width: 90%;
        text-align: center;
    }

    .first_Row {
        display: block;
        align-items: center;
        width: 100%;
    }

    .firstName {
        width: 100%;
    }

    .firstName h1 {
        font-size: 3.3vw;
        font-weight: 500;
        white-space: normal;
        overflow: auto;
        text-overflow: ellipsis;
        margin-right: 1vw;
    }

    .pay_Installment_main_container {
        border: none;
        position: relative;
        padding: 1.5vw;
        margin: 3vw 0;
    }

    .pay_Installment_title_container {
        width: 100%;
        background-color: var(--secondary-background-color);
        position: absolute;
        padding: 0 0.3vw;
        border-bottom: 0.2vw solid var(--primary-text-color);

    }

    .pay_Installment_title_container h1 {
        text-align: center;
        font-size: 4vw;
        font-weight: 500;
    }

    .pay_Installment_content_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 2vw
    }

    .pay_Installment_left_container {
        width: 100%;
        padding: 0 1.5vw 0 0;
        margin-top: 4vw;
    }

    .pay_Installment_right_container {
        width: 100%;
        padding: 0 1.5vw 0 0;
    }

    .StudentFeesInfo_main_contianer .common-dropdown-container select {
        margin-bottom: 2vw;

    }

    .students_details_title_container h1 {
        font-size: 4.5vw;
        margin-bottom: 3vw;
        font-weight: 500;
    }
}