@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.table_container {
    width: 100%;
    margin: 0.5vw 0 0 0;
}

.student_table {
    width: 100%;
    border-collapse: collapse;
}

.student_table th {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);
}

.student_table tbody tr {
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: translateY(-5px);
    }
}

.student_table th,
.student_table td {
    padding: 0.5vw;
    // border: 0.1vw solid var(--primary-text-color);
    text-align: center;
    font-size: 1vw;
    font-weight: 500;
    box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.1);
}

.student_table tbody tr:nth-child(even) td {
    background-color: var(--table-even-row-background-color);
}

.student_table tbody tr:nth-child(odd) td {
    background-color: var(--secondary-text-color);
}


.link-text {
    text-decoration: none;
}

.info-icon {
    color: var(--primary-color);
    font-size: 1.5vw;

}

.pdf-icon {
    color: var(--primary-error-color);
    font-size: 1.5vw;

}

.student_table th:nth-child(3),
.student_table td:nth-child(3) {
    min-width: 20vw;
    /* Adjust the width as needed */
}

.student_table th:nth-child(3),
.student_table td:nth-child(3) {
    min-width: 20vw;
    /* Adjust the width as needed */
}

.showing_index p {
    font-size: 1.1vw;
}

.pagination {
    margin: 0.5vw 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pagination_container p {
    font-size: 1.1vw;
}

.pagination_controls {
    display: flex;
    font-size: 1.1vw;
    // justify-content: space-around;
    align-items: center;

    .display_container {
        display: flex;
        margin-left: 1.1vw;

        input {
            padding: 0 0 0 0.4vw;
            font-size: 1.1vw;
        }
    }

    .extra_space {
        margin-left: 0.7vw;
    }

    .arrows_container {
        display: flex;
        align-items: center;
    }

    .left_arrow_btn_container,
    .right_arrow_btn_container {
        border: 0.1vw solid var(--primary-border-color);
        padding: 0 0.4vw;
        font-weight: bold;
        cursor: pointer;
    }

    .left_arrow_active,
    .right_arrow_active {
        border: 0.1vw solid var(--primary-color);
        padding: 0 0.4vw;
        font-weight: bold;
        // background-color: var(--primary-color);
        color: var(--primary-color);
        cursor: pointer;
    }

    .number_pages_parent {
        display: flex;
        align-items: center;
    }

    .numbers_contianer {
        padding: 0vw 0.6vw;
        cursor: pointer;
    }

    .current_page_active {
        background-color: var(--primary-color);
        color: var(--secondary-text-color);
        padding: 0.06vw 0.6vw;
    }
}

.export-students-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    margin-top: 1vw;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .export-students-button {
        margin-top: 4vw;
    }

    .export-students-button>.primary-button {
        width: 100%;
    }

    .table_container {
        width: 100%;
        margin-top: 2.5vw;
    }

    .student_table {
        display: inherit;
        width: 100%;
        overflow: auto;
        //border-collapse: collapse;
    }


    .student_table th {
        background-color: var(--primary-color);
        color: var(--secondary-text-color);
    }


    .student_table th,
    .student_table td {
        padding: 2vw;
        // border: 0.1vw solid var(--primary-text-color);
        text-align: center;
        font-size: 4.55vw;
        font-weight: 400;
        box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.1);

    }

    .pagination {
        margin: 0.5vw 0 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .pagination_container p {
        font-size: 5.5vw;
    }

    .pagination_container {
        margin-bottom: 0.5vw;
    }

    .pagination_controls {
        display: flex;
        font-size: 4vw;
        flex-direction: column;
        // justify-content: space-around;
        align-items: baseline;

        .display_container {
            display: flex;
            margin-left: 0vw;
            padding: 0 0 2vw 0;
            margin-bottom: 3vw;
            margin-top: 2vw;

            input {
                padding: 0 0 0 0.5vw;
                font-size: 6vw;
            }
        }

        .extra_space {
            //margin-left: 2vw;
        }

        .arrows_container {
            display: flex;
            //flex-direction: column;
            align-items: center;
            font-size: 7.8vw;
            margin-top: 2vw;
            width: 100%;

            // justify-content: space-between;
        }

        .left_arrow_btn_container,
        .right_arrow_btn_container {
            border: 0.2vw solid var(--primary-border-color);
            padding: 0 1vw;
            font-weight: bold;
            cursor: pointer;
            width: 10vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .left_arrow_active,
        .right_arrow_active {
            border: 0.2vw solid var(--primary-color);
            padding: 0 1vw;
            font-weight: bold;
            // background-color: var(--primary-color);
            color: var(--primary-color);
            cursor: pointer;
            width: 10vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .number_pages_parent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //font-size: 6vw;
            flex: 1;
        }

        .numbers_contianer {
            padding: 0vw 2vw 0 2vw;
            cursor: pointer;
            font-size: 6vw;
        }

        .current_page_active {
            background-color: var(--primary-color);
            color: var(--secondary-text-color);
            padding: 0.06vw 0.6vw;
            font-size: 8vw;
        }

        .display_container p {
            font-size: 6vw;
            margin-right: 1vw;
        }

        .number_pages_parent div {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
        }
    }
}