@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.post_login_header_container {
    width: 100%;
    background-color: var(--secondary-background-color);
    padding: 0.33vw 1.25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    // background-image: radial-gradient(circle at 30% 30%, #FDE7F3 0, #EAE9FF 46%, #DFFFEE 100%);

}


.post_login_header_container_linear_gradient {
    width: 100%;
    background-color: var(--secondary-background-color);
    padding: 0.33vw 1.25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    background-image: radial-gradient(circle at 30% 30%, #FDE7F3 0, #EAE9FF 46%, #DFFFEE 100%);

}

.post-header-logo {
    width: 100%;
    height: auto;
}

.post-header-logo-container {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.post_logout_button_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--secondary-background-color);
    padding: 0.33vw 1.25%;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    // background-image: radial-gradient(circle at 30% 30%, #FDE7F3 0, #EAE9FF 46%, #DFFFEE 100%);

}


.post_logout_button_container_linear_gradient {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--secondary-background-color);
    padding: 0.33vw 1.25%;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    background-image: radial-gradient(circle at 30% 30%, #FDE7F3 0, #EAE9FF 46%, #DFFFEE 100%);

}

.profile-icon-container svg {
    width: 2vw;
    height: 2vw;
    cursor: pointer;
}

.user-mobile-icon-container {
    display: none;
}

.notification-icon-container svg {
    width: 2vw;
    height: 2vw;
    margin-right: 1vw;
    cursor: not-allowed;
}

.change-password-button-profile-popup {
    margin-left: 1.2vw;
}

.modal-overlaycontent_notification {
    width: 100%;
    height: 100vh;
    background-color: var(--modal-background-color);
    position: absolute;
    top: 0;
    left: 0;
    // display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    margin-top: 3.5vw;
}

.modal-content_notification {
    background-color: var(--secondary-text-color);
    border-radius: 1.2vw;
    overflow-y: auto;
    width: 25%;
    height: 60%;
    // margin-bottom: 30vw;
    margin-left: 70vw;
    margin-top: 0.8vw;

}

.modal-overlaycontent_profile {
    width: 100%;
    height: 100vh;
    background-color: var(--modal-background-color);
    position: absolute;
    top: 0;
    left: 0;
    // display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    margin-top: 3.5vw;
}

.modal-content_profile {
    background-color: var(--secondary-text-color);
    border-radius: 1.2vw;
    overflow-y: auto;
    width: 22%;
    // margin-bottom: 30vw;
    margin-left: 77vw;
    margin-top: 0.8vw;

}

@media only screen and (max-width:$mobileMaxWidth) {

    .post_login_header_container {
        //justify-content: space-between;
        padding: 2.8vw 3%;
        z-index: 999;
    }

    .post-header-logo-container {
        width: 55%;
    }

    .post_logout_button_container {
        display: none;
    }

    .post-header-main-container {
        width: 67%;
        height: 100vh;
        background-color: var(--secondary-background-color);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .post-header-content {
        width: 90%;
        height: 100vh;
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        flex-direction: column;
    }

    .post_logout_button_container_linear_gradient {
        display: none;


    }


    .post-mobile-header-close-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .post-mobile-header-close-container p {
        font-weight: 500;
        font-size: 10vw;
    }

    .post-login-bottom-container {
        width: 100%;
        margin-bottom: 14vh;
    }

    .post_mobile_logout_container {
        display: flex;
        justify-content: center;
        margin-bottom: 20vw;
        // margin-top: 49vw;

    }

    .profile-icon-container {
        display: none;
    }

    .user-mobile-icon-container {
        display: flex;
    }

    .user-mobile-icon-container svg {
        width: 8vw;
        height: 8vw;
        margin-left: 3vw;
        margin-bottom: 2vw;
    }

    .notification-icon-container svg {
        width: 8vw;
        height: 8vw;
        margin-left: 22vw;
    }

    .user-main-icon {
        display: flex;
        justify-content: center;
    }

    .user-main-icon svg {
        width: 25vw;
        height: 25vw;
    }

    .user-detail-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .user-name-container {
        display: flex;
        justify-content: center;
        font-weight: 500;
        //margin-top: 3vw;
    }

    .user-email-container {
        display: flex;
        justify-content: center;
        font-weight: 400;
        border-bottom: 0.5vw solid black;
    }

    .edit-user-icon {
        display: flex;
        flex-direction: row;
        margin-top: 4vw;
    }

    .edit-user-icon p {
        font-size: 4.5vw;
        margin-left: 4.5vw;
        margin-top: 0.5vw;
    }

    .edit-user-icon svg {
        height: 7vw;
        width: 7vw;
    }

    .subscription-icon-container {
        display: flex;
        flex-direction: row;
        margin-top: 1vw;
    }

    .subscription-icon-container p {
        font-size: 4.5vw;
        margin-left: 4.5vw;
        margin-top: 0.5vw;
    }

    .subscription-icon-container svg {
        width: 7vw;
        height: 7vw;
    }

    .change-password-icon-container {
        display: flex;
        flex-direction: row;
        margin-top: 1vw;
    }

    .change-password-icon-container svg {
        width: 7vw;
        height: 7vw;
    }

    .change-password-icon-container p {
        font-size: 4.5vw;
        margin-left: 4.5vw;
        margin-top: 0.5vw;
    }



    .modal-overlaycontent_notification {
        width: 100%;
        height: 100vh;
        background-color: var(--modal-background-color);
        position: absolute;
        top: 0;
        left: 0;
        //display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        margin-top: 15vw;
    }

    .modal-content_notification {
        background-color: var(--secondary-text-color);
        border-radius: 2vw;
        overflow-y: auto;
        width: 90%;
        height: 60%;
        // margin-bottom: 117vw;
        margin-left: 5vw;
        margin-top: 1.5vw;

    }
}