@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.consent-checkbox-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.3vw;
}

.consent-checkbox-main-container .consent-checkbox.disabled:hover {
    cursor: not-allowed;
}

.consent-checkbox-main-container .consent-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 0.2vw;
    width: 1.2vw;
    height: 0.8vw;
    margin-top: 0.2vw;
}

.consent-checkbox-main-container .consent-checkbox:hover {
    cursor: pointer;
}

.consent-checkbox-main-container .consent-checkbox :first-child {
    font-size: 0.5vw;
}

.consent-checkbox-main-container .consent-text {
    margin-left: 0.2vw;
}

.consent-checkbox-main-container .consent-text p {
    font-size: 0.8vw;
    font-weight: 300;
    position: relative;
}

.consent-checkbox-main-container .consent-text p svg {
    margin-left: 0.3vw;
    font-size: 1vw;
    color: var(--primary-error-color);
    position: absolute;
    top: 1.35vw;
}

.consent-text .consent-error-text-container {
    position: absolute;
    top: -0.8vw;
    right: 5.75vw;
    background-color: var(--secondary-background-color);
    border: 0.1vw solid var(--primary-error-color);
    padding: 0vw 0.15vw;
    border-radius: 0.25vw;
    max-width: 100%;
}

.consent-text .consent-error-text-container.registration-page {
    right: 13.9vw;
}

.consent-text .consent-error-text-container::after {
    content: '';
    position: absolute;
    top: 1.6vw;
    right: 1.65vw;
    width: 0;
    height: 0;
    border-left: 0.6vw solid transparent;
    border-right: 0.6vw solid transparent;
    border-top: 0.6vw solid var(--primary-error-color);
}

.consent-text .consent-error-text-container p {
    font-size: 1vw;
    font-weight: 300;
    color: var(--primary-error-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

@media only screen and (max-width:$mobileMaxWidth){

    .consent-checkbox-main-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0.3vw;
        //padding-left:1.5vw;
    }

    .consent-checkbox-main-container .consent-text p {
        font-size: 3.5vw;
        font-weight: 300;
        position: relative;
        margin-left: 0.5vw;
    
    }

    .consent-checkbox-main-container .consent-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.26vw solid var(--primary-text-color);
        border-radius: 1vw;
        width: 5.2vw;
        height: 3.1vw;
        margin-top: 1vw;
    
    }
    
    .consent-checkbox-main-container .consent-checkbox :first-child {
        font-size: 2vw;
    }
    
    .consent-checkbox-main-container .consent-text p svg {
        margin-left: 0.3vw;
        font-size: 4.5vw;
        color: var(--primary-error-color);
        position: absolute;
        top: 6vw;
    }

    .consent-text .consent-error-text-container p {
        font-size: 3vw;
        font-weight: 400;
        color: var(--primary-error-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .consent-text .consent-error-text-container {
        position: absolute;
        top: 0vw;
        right: -0.5vw;
        background-color: var(--secondary-background-color);
        border: 0.1vw solid var(--primary-error-color);
        padding: 0vw 0.15vw;
        border-radius: 0.5vw;
        max-width: 100%;
    }

    .consent-text .consent-error-text-container.registration-page {
        right: -3.2vw;
    }

    .consent-text .consent-error-text-container::after {
        content: '';
        position: absolute;
        top: 4.8vw;
        right: 0.9vw;
        width: 0;
        height: 0;
        border-left: 1.2vw solid transparent;
        border-right: 1.2vw solid transparent;
        border-top: 1.2vw solid var(--primary-error-color);
    }
}