.register-page-main-container {
    width: 100%;
}

.register-page-grid-container {
    display: grid;
    grid-template-columns: repeat(8, 8%);
    column-gap: 2%;
    justify-content: center;
}

@media screen and (max-width: 480px) {
    .register-page-grid-container {
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
    }

    .register-page-main-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}