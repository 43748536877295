@import '../../../Utils/Styles/Colors.css';

.common-button-div {
    background-color: var(--primary-color);
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    padding: 4px 0px 4px 0px;
    border: 2px solid var(--primary-color);
    transition: 0.3s ease-out;
    padding-left: 16px;
    padding-right: 16px;
}

.common-button-text {
    color: var(--secondary-text-color);
    font-weight: 500;
    font-size: 20px;
}

.common-button-div:hover:not(.disabled) {
    background-color: var(--secondary-background-color);
    cursor: pointer;
}

.common-button-div:hover:not(.disabled) .common-button-text {
    color: var(--primary-text-color);
}

.common-button-div.disabled {
    pointer-events: none;
    /* Prevents click events on disabled button */
    opacity: 0.5;
    /* Reduces opacity of disabled button */
}

@for $i from 1 through 100 {
    $width: $i * 1%;

    .button-width-#{$i} {
        width: $width;
    }

    .button-margin-top-#{$i} {
        margin-top: #{$i}px;
    }
}