@import '../../Utils//Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.subscription-parent-section {
    height: 100vh;
    width: 100vw;
    margin: 0 0 13vw 0;
    padding: 0;
    position: relative;

}

// background shapes style start

.background-shapes-container {
    z-index: -1;
}

.blue_hexagon {
    position: absolute;
    top: 2vw;
    z-index: -2;
    width: 15vw;
    margin: 0 auto;
}

.green_hexagon {
    position: absolute;
    top: 17vw;
    z-index: -2;
    width: 8.5vw;
    margin: 0 auto;
}

.blue_gear {
    position: absolute;
    bottom: -16vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.green_gear {
    position: absolute;
    bottom: -16vw;
    left: 7.9vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.green_rectangle {
    position: absolute;
    bottom: -16vw;
    right: 0vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.blue_square {
    position: absolute;
    bottom: -16vw;
    right: 6.8vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.green_circle {
    position: absolute;
    top: 8vw;
    right: 0;
    z-index: -2;
    width: 8.4vw;
    margin: 0 auto;
}

.blue_circle {
    position: absolute;
    top: 19vw;
    right: 0;
    z-index: -2;
    width: 4vw;
    margin: 0 auto;
}

// background shapes style end

.subscription-content-parent {
    margin: 0 auto;
    padding: 2vw 2vw 6vw 4vw;
    border: 0.1vw solid var(--primary-border-color);
    max-width: 83%;
    border-radius: 1vw;
    box-shadow: 0.1vw 0.1vw 0.5vw 0.1vw rgba(0, 0, 0, 0.2);
    z-index: 3;
    background-color: var(--secondary-background-color);
}

.header_text_wraper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_text_wraper h1 {
    font-size: 2vw;
    font-weight: 500;
}

.subscription-para-secondary-wrapper {
    margin-top: 1vw;
    margin-left: 0.8vw;
}

.subscription-para-wrapper p,
.subscription-para-secondary-wrapper p,
.subscription-para-ternary-wrapper p {
    font-size: 0.9vw;
}

.subscription-para-ternary-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.8vw;
}

.switch {
    width: 4vw;
    height: 2vw;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0.1vw 0.1vw 0.5vw 0.1vw rgba(0, 0, 0, 0.2);
    margin: 0 0.4vw;

}

.switch-off {
    background-color: var(--primary-color);
}

.switch-on {
    background-color: var(--secondary-color);
}

.switch-thumb {
    width: 2vw;
    height: 1.8vw;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0.1vw;
    left: 0.1vw;
    transition: all 0.3s ease;
}

.switch-on .switch-thumb {
    left: 2vw;
}


.carousal-individual-parent-container {
    border: 0.1vw solid var(--primary-border-color);
    border-radius: 1vw;
    background-color: var(--secondary-background-color);
    margin: 3vw 0.7vw 0 0;
    box-shadow: 0.1vw 0.1vw 0.5vw 0.1vw rgba(0, 0, 0, 0.2);

}

.carsousal-header-wrapper {
    background-color: var(--primary-color);
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.carsousal-header-wrapper h1 {
    color: var(--secondary-text-color);
    font-size: 2vw;
    font-weight: 500;
    text-align: center;
}

.month-span {
    font-size: 1.5vw;

}

.carousal-content-wrapper {
    padding: 1vw;
}

.tick_wrong_container {
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--secondary-text-color);
    font-size: 1.5vw;
}

.tick-green {
    background-color: var(--secondary-color);
}

.tick-red {
    background-color: var(--primary-error-color);
}

.carousal-content-wrapper {
    display: flex;
    align-items: center;
}

.subscription_features_list h4 {
    margin-left: 1vw;
    font-size: 1.3vw;
    font-weight: 500;
}

.react-multi-carousel-list {
    position: static !important;
}

.react-multi-carousel-dot-list {
    bottom: 12vw !important;
}

.react-multiple-carousel__arrow {
    background-color: var(--primary-color) !important;
}

.react-multi-carousel-dot--active button {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important
}

.react-multiple-carousel__arrow--right {
    right: calc(4% + 5vw) !important;

}

.react-multiple-carousel__arrow--left {
    left: calc(4% + 6vw) !important;
}