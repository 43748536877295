@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';


.PastAcademicReports_main_container {
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 1vw;
    position: relative;
    padding: 1.5vw;
    margin-top: 2vw;
}

.PastAcademicReports_title_container {
    background-color: var(--secondary-background-color);
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    padding: 0 0.3vw;
}

.PastAcademicReports_title_container h1 {
    font-size: 1.5vw;
    font-weight: 500;
}

.PastAcademicReports_content_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
}

.PastAcademicReports_left_container {
    width: 50%;
    padding: 0 1.5vw 0 0;
}

.work_contianer,
.Residential_contianer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 1vw 0;
}

.work_text_container h3,
.Residential_text_container h3 {
    font-size: 1vw;
    font-weight: 500;
}



.PastAcademicReports_right_container {
    width: 50%;
    padding: 0 0 0 1.5vw;

}

.par_board_container {
    margin-top: 0.8vw;
}

.PastAcademicReports_Institute_name {
    margin-bottom: 0vw;
}

.row_class {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.parent_right_dropDown_container {
    width: 50%;
    //margin-top: 0.8vw;
    margin-bottom: 0.8vw;
    padding-left: 1.5vw;
}

.parent_left_dropDown_container {
    width: 50%;
    // margin-top: 0.8vw;
    padding-right: 1.5vw;
}

.PastAcademicReports_Institute_name,
.Board_container,
.par_board_container,
.Medium_container,
.standard_container {
    width: 48%;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .PastAcademicReports_main_container {
        border: none;
        // border-radius: 1vw;
        position: relative;
        padding: 2vw;
        // margin-top: 2vw;
        // display:none;
    }

    .PastAcademicReports_title_container {
        background-color: var(--secondary-background-color);
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, -50%);
        padding: 0 0.3vw;
        margin-top: 4vw;
    }

    .PastAcademicReports_title_container h1 {
        font-size: 4.5vw;
        font-weight: 500;
        margin-bottom: 2vw;
    }

    .PastAcademicReports_content_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }

    .PastAcademicReports_left_container {
        width: 100%;
        padding: 0 1.5vw 0 0;
        margin-top: 3vw;
    }

    .work_contianer,
    .Residential_contianer {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 1vw 0;
    }

    .work_text_container h3,
    .Residential_text_container h3 {
        font-size: 4vw;
        font-weight: 500;
    }

    .PastAcademicReports_right_container {
        width: 100%;
        padding: 0 1.5vw 0 0;

    }

    .par_board_container {
        margin-top: 1vw;
    }



    .PastAcademicReports_Institute_name,
    .Board_container,
    .par_board_container,
    .Medium_container,
    .standard_container {
        width: 100%;
    }

    .parent_right_dropDown_container {
        width: 100%;
        padding-left: 0;
        margin-bottom: 2.5vw;
    }

    .parent_left_dropDown_container {
        width: 100%;
        padding-right: 0;
        margin-bottom: 2.5vw;
    }
}