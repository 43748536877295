@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';


.student_details_main_container {
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 1vw;
    position: relative;
    padding: 1.5vw;
}

.students_details_title_container {
    background-color: var(--secondary-background-color);
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    padding: 0 0.3vw;
}

.students_details_title_container h1 {
    font-size: 1.5vw;
    font-weight: 500;
}

.student_details_content_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
}

.student_details_left_container {
    width: 50%;
    padding: 0 1.5vw 0 0;
}

.gender_contianer,
.section_contianer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 1vw 0;
}

.gender_text_container,
.section_text_container {
    width: 15%;
}

.gender_text_container h3,
.section_text_container h3 {
    font-size: 1vw;
    font-weight: 500;
}

.gender_category_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.section_category_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
}

.radio_button_cateogry_wrapper input {
    margin-right: 0.5vw;
}

.radio_button_cateogry_wrapper label {
    font-size: 1vw;
    font-weight: 400;
}

.radio_button_cateogry_wrapper {
    display: flex;
    align-items: center;
}

.student_details_right_container {
    width: 50%;
    padding: 0 0 0 1.5vw;

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    /* Drop shadow effect */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

.student_aadhar_no .input-field-outer-container {
    margin-bottom: 0.8vw;
}

.radio-spinner {
    animation: radioloaderspin 1s linear infinite;
    font-size: 1.4vw;
    margin-bottom: 0.2vw;
    // margin-left: 0.4vw;
    // position: absolute;
    // top: 0.55vw;
    // right: 1.5vw;
}

@keyframes radioloaderspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.row_class {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.left_parent_dropDown_container {
    width: 50%;
    margin-bottom: 0.8vw;
    // padding: 0.63vw;
    padding-right: 1.5vw;
}

.right_parent_dropDown_container {
    width: 50%;
    padding-left: 1.5vw;
    margin-bottom: 0.8vw;
}


.students_first_name,
.students_middle_name,
.students_last_name,
.students_dob,
.students_email_id,
.students_mobile_number,
.gender_contianer,
.section_contianer,
.institue_container,
.stream_container,
.students_board_container,
.medium_container,
.division_container,
.students_standard_container,
.rollno_container,
.students_last_name,
.student_aadhar_no {
    width: 48%;
}

.main-container-with-photo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.main-container-with-photo .main-row-container-basic {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.main-container-with-photo .right-side-photo-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image-upload-instructions-container p {
    font-size: 1vw;
    margin-top: 0.3vw;
}

.main-container-with-photo .right-side-photo-container .photo-view {
    border: 0.1vw solid var(--primary-text-color);
    height: 11vw;
    width: 9vw;
    overflow: hidden;
    position: relative;

    label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        cursor: pointer;
    }
}

.main-container-with-photo .row-class-with-photo {
    flex-direction: column;
}

.main-container-with-photo .row-class-with-photo {

    .students_first_name,
    .students_middle_name,
    .students_last_name,
    .students_dob,
    .students_mobile_number,
    .students_email_id {
        width: 96%;
    }
}

@media only screen and (max-Width:$mobileMaxWidth) {

    .student_details_main_container {
        border: none;
        //border-radius: 1vw;
        position: relative;
        padding: 1.5vw;
    }

    .students_details_title_container h1 {
        font-size: 4.5vw;
        font-weight: 500;
        margin-bottom: 3vw;
    }

    .gender_text_container h3,
    .section_text_container h3 {
        font-size: 4vw;
        font-weight: 500;
    }

    .gender_contianer,
    .section_contianer {
        //display: flex;
        display: block;
        align-items: center;
        width: 100%;
        margin: 1vw 0;
    }

    .gender_category_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        //margin-top: 10vw;
        //margin-right: 20vw;
    }

    .section_category_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 61%;
    }

    .radio_button_cateogry_wrapper label {
        font-size: 4vw;
        font-weight: 400;
        margin-left: 3vw;
        margin-top: 0.5vw;
        margin-bottom: 0.5vw;
    }

    .left_parent_dropDown_container {
        width: 100%;
        padding-right: 0;
        margin-bottom: 2.5vw;
    }

    .right_parent_dropDown_container {
        width: 100%;
        padding-left: 0;
        margin-bottom: 2.5vw;
    }

    .students_first_name,
    .students_middle_name,
    .students_last_name,
    .students_dob,
    .students_email_id,
    .students_mobile_number,
    .gender_contianer,
    .section_contianer,
    .institue_container,
    .stream_container,
    .students_board_container,
    .medium_container,
    .division_container,
    .students_standard_container,
    .rollno_container,
    .students_last_name,
    .student_aadhar_no {
        width: 100%;
    }

    .students_details_title_container {
        background-color: var(--secondary-background-color);
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, -50%);
        padding: 0 0.3vw;
    }

    .students_details_title_container h1 {
        font-size: 1.5vw;
        font-weight: 500;
    }

    .main-container-with-photo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .main-container-with-photo .main-row-container-basic {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .main-container-with-photo .right-side-photo-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .image-upload-instructions-container p {
        font-size: 3vw;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }

    .main-container-with-photo .right-side-photo-container .photo-view {
        border: 0.2vw solid var(--primary-text-color);
        height: 25vw;
        width: 23vw;
        overflow: hidden;
        position: relative;

        label {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            cursor: pointer;
        }
    }

    .main-container-with-photo .row-class-with-photo {
        flex-direction: column;
    }

    .main-container-with-photo .row-class-with-photo {

        .students_first_name,
        .students_middle_name,
        .students_last_name,
        .students_dob,
        .students_mobile_number,
        .students_email_id {
            width: 100%;
        }
    }

}