@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.primary-button {
    background-color: var(--primary-color);
    border: 0.15vw solid var(--primary-color);
    border-radius: 0.25vw;
    padding: 0.2vw 0.95vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-text-color);
    transition: 0.3s ease-out;
}

.primary-button.disabled {
    opacity: 0.7;
}


.primary-button p {
    font-weight: 500;
    font-size: 1.23vw;
}

.primary-button:hover {
    background-color: var(--secondary-background-color);
    cursor: pointer;
    color: var(--primary-text-color);
}

.primary-button.disabled:hover {
    background-color: var(--primary-color);
    cursor: not-allowed;
    color: var(--secondary-text-color);
}

.spinner {
    animation: spin 1s linear infinite;
    font-size: 1.4vw;
    margin-left: 0.4vw;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: $mobileMaxWidth) {
    .primary-button {
        padding: 0.2vw 1.3vw;
        border-radius: 1.2vw;
        border-width: 0.5vw;
        margin-bottom: 2vw;
        margin-top:2vw;
    }

    .primary-button p {
        font-size: 6vw;
    }

    .spinner {
        animation: spin 1s linear infinite;
        font-size: 6vw;
        margin-left: 0.7vw;
    }
    
    .Admission_form_common_button .Admissiion_form_common_next_button{
        display: flex;
       // background-color: black;
       // margin-left: 50vw;
    }


}
