@import '../../../Utils/Styles/Colors.css';

@import '../../../Utils/Styles/StylesConfig.scss';

header.header-main-container {
    width: 100%;
    background-color: var(--secondary-background-color);
    padding: 0.33vw 1.25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
}

.header-logo-container {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-logo {
    width: 100%;
    height: auto;
}

.header-nav-container {
    flex: 1;
    margin-left: 1.45%;
}

.header-nav-container ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.header-nav-container ul li {
    margin-right: 1.85%;
    transition: 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-nav-container ul li:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.header-nav-container ul li a {
    font-weight: 500;
    font-size: 1.25vw;
}

.header-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.header-buttons-container .header-register-button {
    margin-left: 1.1vw;
}

.hambuger-icon-container {
    display: none;
}

.header-mobile-menu-container {
    display: none;
}

@media only screen and (max-width: $mobileMaxWidth) {
    .hambuger-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-buttons-container {
        display: none;
    }

    .header-nav-container {
        display: none;
    }

    .header-logo-container {
        width: 55%;
    }

    header.header-main-container {
        justify-content: space-between;
        padding: 2.8vw 3%;
        z-index: 999;
    }

    .header-mobile-menu-container {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100vh;
        background-color: var(--modal-background-color);
        justify-content: flex-end;
        align-items: flex-start;
        display: flex;
        transition: right 0.3s ease;
    }

    .header-mobile-menu-content-container {
        width: 60%;
        height: 100vh;
        background-color: var(--secondary-background-color);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .header-mobile-menu-container.active {
        right: 0;
    }

    .mobile-header-menu-content {
        width: 90%;
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .mobile-header-close-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .mobile-header-close-container p {
        font-weight: 500;
        font-size: 10vw;
    }

    .mobile-header-nav-links-container {
        margin-top: 4vw;
    }

    .mobile-header-nav-links-container ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mobile-header-nav-links-container ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2vw;
        width: 100%;
        transition: 0.3s ease-out;
    }

    .mobile-header-nav-links-container ul li .mobile-header-nav-arrow {
        font-size: 7vw;
    }

    .mobile-header-nav-links-container ul li:hover {
        color: var(--primary-color);
    }

    .mobile-header-nav-links-container ul li a {
        font-weight: 500;
        font-size: 6vw;
    }

    .mobile-header-upper-options {
        width: 100%;
    }

    .mobile-header-lower-options {
        width: 100%;
        margin-bottom: 14vh;
    }

    .mobile-header-buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .mobile-header-lower-options .mobile-header-buttons-container .primary-button.mobile-header-register-button {
        margin-top: 2vh;
    }

}