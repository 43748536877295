@import '../../Utils//Styles/Colors.css';
@import '../../Utils//Styles/StylesConfig.scss';


.Admission_tab_section_container {
    padding: 0.5vw 6vw;
    margin: 0 auto;
    background-color: var(--secondary-background-color);
}


.tab-container {
    width: 100%;
}

.tabs {
    display: flex;
}

.tab {
    cursor: pointer;
    padding: 0.5vw 0;
    margin: 0 0 0.5vw 1vw;
    border: 0.1vw solid var(--primary-color);
    border-radius: 0.5vw;
    width: 25%;
    text-align: center;
    background-color: var(--primary-color);

}

.tab:nth-child(1) {
    margin: 0 0 0.5vw 0;
}

.tab_header_text {
    font-size: 1.3vw;
    font-weight: 500;
    color: var(--secondary-text-color);
}

.tab.active {
    background-color: var(--secondary-text-color);
    border: 0.1vw solid var(--primary-color);
    border-bottom: none;
    border-radius: 0.5vw 0.5vw 0 0;
    margin: 0 0 0 1vw;
    transition: background-color 0.3s ease, border-color 0.3s ease, margin 0.3s ease;
}

.tab.active:nth-child(1) {
    margin: 0;
}

.active_text {
    color: var(--primary-color) !important;
}

.tab-content {
    border: 0.1vw solid var(--primary-color);
    border-radius: 0 0vw 0.5vw 0.5vw;
    padding: 1.5vw;
    position: relative;
}

.tab-content::before {
    content: "";
    position: absolute;
    top: -0.2vw;
    left: 0;
    right: 0;
    height: 1%;
    // width: 21.15vw; 
    width: 16.7vw;
    border-top: 1vw solid var(--secondary-text-color);
}

.tab-content[data-tab="1"]::before {
    left: 17.7vw;
    width: 16.8vw;
    top: -0.06vw;

}

.tab-content[data-tab="2"]::before {
    width: 16.8vw;
    left: 35.5vw;
    top: -0.06vw;
}

.tab-content[data-tab="3"]::before {
    width: 16.8vw;
    left: 53.3vw;
    top: -0.06vw;
}


.tab-content[data-tab="4"]::before {
    width: 16.7vw;
    left: 71.2vw;
    top: -0.06vw;
}

/* Adjustments for tab content visibility */
.tab-content>div {
    // display: none;
}

.tab-content>div:first-child {
    // display: block;
}

.tab-content .details_page_button_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.1vw;
}

.tab-content .details_page_button_container .edit_button_container,
.tab-content .details_page_button_container .delete_button_container {
    display: flex;
}

.tab-content .details_page_button_container .edit_button_container {
    margin-left: 1.1vw;
}

.tab-content .ParentDetails_main_container,
.tab-content .PastAcademicReports_main_container {
    margin-top: unset;
}

.tab-content .details_page_button_container .delete_button_container .primary-button {
    background-color: var(--over-due-pie_chart-color);
    border-color: var(--over-due-pie_chart-color);
}

.tab-content .details_page_button_container .delete_button_container .primary-button:hover {
    background-color: var(--secondary-background-color);
}

.tab-content .details_page_button_container .delete_button_container .primary-button.disabled:hover {
    background-color: var(--over-due-pie_chart-color);
}

.modal-overlaycontent_studentfees.delete_active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container {
    margin: 1.5vw;
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container p {
    font-size: 1.3vw;
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container p span {
    font-weight: 600;
}


.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_modal_buttons_container,
.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_error_modal_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1.1vw;
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_modal_buttons_container div:first-child {
    background-color: var(--over-due-pie_chart-color);
    border-color: var(--over-due-pie_chart-color);
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_modal_buttons_container div:first-child:hover {
    background-color: var(--secondary-background-color);
}

.modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_modal_buttons_container div.disabled:first-child:hover {
    background-color: var(--over-due-pie_chart-color);
}

.admission-form-error-container+.Primary_button {
    margin-top: 0.1vw;
}

.Primary_button {
    display: flex;
    justify-content: center;
    margin: 2vw 0 0 0;

}

.modal-overlaycontent_studentfees {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6vw;
    z-index: 99999;
    overflow-y: auto;
}

.modal-content_studentfees {
    background-color: var(--secondary-text-color);
    border-radius: 0.7vw;
    overflow-y: auto;
}

.dropdown_menu {
    display: none;
}

@media only screen and (max-width:$mobileMaxWidth) {
    .tabs {
        display: none;
    }

    .dropdown_menu {
        display: block;
        margin-bottom: 1vw;
        margin-top: 1vw;
    }

    .tab-content {
        border: 0.2vw solid;
        border-radius: 1vw;
        padding: 1.5vw;
        position: relative;
    }

    .tab-content::before {
        display: none;
    }

    .modal-content_studentfees {
        background-color: var(--secondary-text-color);
        border-radius: 0.7vw;
        overflow-y: auto;
        padding: 3vw;
    }

    .modal-overlaycontent_studentfees.delete_active .modal-content_studentfees {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container {
        margin: 1.5vw;
    }

    .modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container p {
        font-size: 5vw;
    }

    .modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container p span {
        font-weight: 600;
    }


    .modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_modal_buttons_container,
    .modal-overlaycontent_studentfees.delete_active .modal-content_studentfees .delete_modal_main_container .delete_error_modal_buttons_container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1.1vw;
    }



}