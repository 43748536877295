@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.footer-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vw;
    background-color: var(--primary-background-color);
    padding: 1vw 0;
}

.footer-content-container {
    width: 85%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 10vw 5vw;
    gap: 1vw;
}

.footer-logo-container .footer-logo {
    width: 18vw;
    height: auto;
}

.footer-nav-links-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.footer-nav-links-container ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer-nav-links-container ul li {
    margin-left: 1.25vw;
}

.footer-nav-links-container ul li a {
    font-size: 1.3vw;
    font-weight: 500;
    text-decoration: underline;
    transition: 0.3s ease-out;
}

.footer-nav-links-container ul li a:hover {
    cursor: pointer;
    color: var(--primary-color);
}

.footer-content-container .footer-logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer-terms-conditions-copyright-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-terms-conditions-copyright-text-container .terms-privacy-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer-terms-conditions-copyright-text-container .terms-privacy-link .footer-privacy-text {
    margin-left: 1.25vw;
}

.footer-terms-conditions-copyright-text-container .terms-privacy-link p {
    font-size: 1.3vw;
    font-weight: 400;
    color: var(--primary-color);
    transition: 0.3s ease-out;
}

.footer-terms-conditions-copyright-text-container .terms-privacy-link p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.footer-terms-conditions-copyright-text-container .copyright-text p {
    font-size: 0.8vw;
    font-weight: 300;
}

.footer-content-container .footer-social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.footer-content-container .footer-social-media-container .footer-social-media-link {
    font-size: 2vw;
    margin-left: 1vw;
    cursor: pointer;
}

.footer-facebook {
    color: #316ff6;
}

.footer-instagram {
    color: #cd486b;
}

.footer-whatsapp {
    color: #25d366;
}

.footer-twitter {
    color: #1da1f2;
}

@media only screen and (max-width: $mobileMaxWidth) {
    .footer-main-container {
        margin-top: 6vw;
        padding: 4vw 0;
    }

    .footer-content-container {
        width: 90%;
        grid-template-columns: unset;
        grid-template-rows: auto auto auto auto;
    }

    .footer-content-container .footer-logo-container {
        justify-content: center;
        grid-row: 1;
        margin: 4vw 0;
    }

    .footer-logo-container .footer-logo {
        width: 60vw;
    }

    .footer-nav-links-container {
        grid-row: 3;
        justify-content: center;
        align-items: center;
        margin: 1vw 0;
    }

    .footer-nav-links-container ul {
        flex-direction: column;
    }

    .footer-nav-links-container ul li {
        margin-left: unset;
        margin-top: 1vw;
    }

    .footer-nav-links-container ul li a {
        font-size: 4vw;
    }

    .footer-terms-conditions-copyright-text-container {
        grid-row: 4;
        align-items: center;
        margin: 3vw 0;
        justify-content: flex-start;
    }

    .footer-terms-conditions-copyright-text-container .terms-privacy-link {
        flex-direction: column;
    }

    .footer-terms-conditions-copyright-text-container .terms-privacy-link p {
        font-size: 4vw;
        margin-top: 1vw;
    }

    .footer-terms-conditions-copyright-text-container .terms-privacy-link .footer-privacy-text {
        margin-left: unset;
    }

    .footer-terms-conditions-copyright-text-container .copyright-text {
        margin-top: 4vw;
    }

    .footer-terms-conditions-copyright-text-container .copyright-text p {
        font-size: 2.5vw;
    }

    .footer-content-container .footer-social-media-container {
        justify-content: center;
        align-items: center;
        grid-row: 2;
        margin: 2vw 0;
    }

    .footer-content-container .footer-social-media-container .footer-social-media-link {
        margin-left: 2vw;
        font-size: 8vw;
        margin-right: 2vw;
    }
}