@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.admission-primary-table-container {
    width: 100%;
    margin: 0.5vw 0 0 0;
}

.no_records_container h3 {
    font-size: 1.2vw;
    font-weight: 500
}

.admission-primary-table {
    width: 100%;
    border-collapse: collapse;
}

.admission-primary-table th {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);

}

.admission-primary-table th:nth-child(1) {
    border-top-left-radius: 0.3vw;
}

.admission-primary-table th:nth-child(10) {
    border-top-right-radius: 0.3vw;
}

.admission-primary-table tbody tr {
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: translateY(-0.25vw);
    }
}

.admission-primary-table th,
.admission-primary-table td {
    padding: 0.5vw;
    text-align: center;
    font-size: 1vw;
    font-weight: 500;
    box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.1);

}

.admission-primary-table tbody tr:nth-child(even) td {
    background-color: var(--table-even-row-background-color);
}

.admission-primary-table tbody tr:nth-child(odd) td {
    background-color: var(--secondary-background-color)
}

.info-icon {
    color: var(--primary-color);
    font-size: 1.5vw;
}

.pdf-icon {
    color: var(--primary-error-color);
    font-size: 1.5vw;
}

.admission-primary-table td:nth-child(2) {
    min-width: 8vw;
}

.admission-primary-table td:nth-child(3) {
    min-width: 10vw;
    // overflow: auto;
}

.admission-primary-table td:nth-child(4) {
    min-width: 5vw;

}

.admission-primary-table td:nth-child(5) {
    min-width: 5vw;
}

.admission-primary-table td:nth-child(6) {
    min-width: 8vw;
}

.admission-primary-table td:nth-child(7),
.admission-primary-table td:nth-child(8),
.admission-primary-table td:nth-child(9) {
    min-width: 8vw;
}

.attendance-primary-link-text {
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
}

.showing_index p {
    font-size: 1.1vw;
}

.pagination {
    margin: 0.5vw 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pagination_container p {
    font-size: 1.1vw;
}

.pagination_controls {
    display: flex;
    font-size: 1.1vw;
    align-items: center;

    .display_container {
        display: flex;
        margin-left: 1.1vw;

        input {
            padding: 0 0 0 0.4vw;
            font-size: 1.1vw;
        }
    }

    .extra_space {
        margin-left: 0.7vw;
    }

    .arrows_container {
        display: flex;
        align-items: center;
    }

    .left_arrow_btn_container,
    .right_arrow_btn_container {
        border: 0.1vw solid var(--primary-border-color);
        padding: 0 0.4vw;
        font-weight: bold;
        cursor: pointer;
    }

    .left_arrow_active,
    .right_arrow_active {
        border: 0.1vw solid var(--primary-color);
        padding: 0 0.4vw;
        font-weight: bold;
        color: var(--primary-color);
        cursor: pointer;
    }

    .number_pages_parent {
        display: flex;
        align-items: center;
    }

    .numbers_contianer {
        padding: 0vw 0.6vw;
        cursor: pointer;
    }

    .current_page_active {
        background-color: var(--primary-color);
        color: var(--secondary-text-color);
        padding: 0.06vw 0.6vw;
    }
}

@media only screen and(max-width:$mobileMaxWidth) {

    .admission-primary-table-container {
        width: 100%;
        margin: 2vw 0 0 0;
        // overflow: auto;
    }

    .admission-primary-table {
        width: 100%;
        border-collapse: collapse;
        overflow: auto;
        display: inherit;
    }

    .no_records_container h3 {
        font-size: 4vw;
        font-weight: 500
    }

    .admission-primary-table th,
    .admission-primary-table td {
        padding: 2vw;
        // border: 0.1vw solid var(--primary-text-color);
        text-align: center;
        font-size: 4.5vw;
        font-weight: 500;
        box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.1);

    }

    .admission-primary-table th:nth-child(1) {
        border-top-left-radius: 2vw;
    }

    .admission-primary-table th:nth-child(10) {
        border-top-right-radius: 2vw;
    }

    .admission-primary-table td:nth-child(1) {
        min-width: 18vw;


    }

    .admission-primary-table td:nth-child(2) {
        min-width: 28vw;
    }

    .admission-primary-table td:nth-child(3) {
        min-width: 40vw;
        // overflow: auto;
    }

    .admission-primary-table td:nth-child(4) {
        min-width: 18vw;

    }

    .admission-primary-table td:nth-child(5) {
        min-width: 18vw;
    }

    .admission-primary-table td:nth-child(6) {
        min-width: 28vw;
    }

    .admission-primary-table td:nth-child(7),
    .admission-primary-table td:nth-child(8),
    .admission-primary-table td:nth-child(9) {
        min-width: 25vw;
    }

    .admission-primary-table td:nth-child(10) {
        min-width: 25vw;
    }

    .pagination {
        margin: 0.5vw 0 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .pagination_container p {
        font-size: 5.5vw;
    }

    .pagination_container {
        margin-bottom: 0.5vw;
    }

    .pagination_controls {
        display: flex;
        font-size: 4vw;
        flex-direction: column;
        // justify-content: space-around;
        align-items: baseline;

        .display_container {
            display: flex;
            margin-left: 0vw;
            padding: 0 0 2vw 0;
            margin-bottom: 3vw;
            margin-top: 2vw;

            input {
                padding: 0 0 0 0.5vw;
                font-size: 6vw;
            }
        }

        .extra_space {
            //margin-left: 2vw;
        }

        .arrows_container {
            display: flex;
            //flex-direction: column;
            align-items: center;
            font-size: 7.8vw;
            margin-top: 2vw;
            width: 100%;

            // justify-content: space-between;
        }

        .left_arrow_btn_container,
        .right_arrow_btn_container {
            border: 0.2vw solid var(--primary-border-color);
            padding: 0 1vw;
            font-weight: bold;
            cursor: pointer;
            width: 10vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .left_arrow_active,
        .right_arrow_active {
            border: 0.2vw solid var(--primary-color);
            padding: 0 1vw;
            font-weight: bold;
            // background-color: var(--primary-color);
            color: var(--primary-color);
            cursor: pointer;
            width: 10vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .number_pages_parent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //font-size: 6vw;
            flex: 1;
        }

        .numbers_contianer {
            padding: 0vw 2vw 0 2vw;
            cursor: pointer;
            font-size: 6vw;
        }

        .current_page_active {
            background-color: var(--primary-color);
            color: var(--secondary-text-color);
            padding: 0.06vw 0.6vw;
            font-size: 8vw;
        }

        .display_container p {
            font-size: 6vw;
            margin-right: 1vw;
        }

        .number_pages_parent div {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
        }
    }
}