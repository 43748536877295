@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.Attendance_Section_conatainer {
    padding: 2vw 6vw;
    margin: 0 auto;
}

.Attendance_Total_Students_container,
.Attendance_Pass_Out_Students_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.Attendance_Pass_Out_Students_container {
    margin-top: 1.3vw;
}

.Attendance_Total_Students_container h3,
.Attendance_Pass_Out_Students_container h3 {
    color: var(--primary-text-color);
    font-size: 1.5vw;
    font-weight: 500;
}

.Attendance_Pie_Chart_Parent_Container {
    display: flex;
    width: 100%;
    border-radius: 0.3vw;
}

.Attendance_pie_Chart_Left_Container {
    padding: 1vw 0 3vw 2vw;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.Attendance_pie_Chart_Right_Container {
    width: 50%;
}


.Attendance_Pie_Chart_Contianer {
    margin: 0.5vw 0 0.5vw 0;
}

.css-66gjpw-MuiResponsiveChart-container {
    width: 30vw !important;
    height: 17vw !important;
    margin: 0 3vw;
}

// Filter Section Starts

.Attendance_filter_section_parent_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1vw 0;
}

.attendance-secondary-dashboard-institute-container,
.attendance-secondary-dashboard-standard-container,
.attendance-secondary-dashboard-medium-container,
.attendance-secondary-dashboard-division-container,
.attendance-secondary-dashboard-Calendar-container {
    padding: 0.4vw 0.4vw 0.4vw 0.8vw;
    border-radius: 0.25vw;
    width: 19%;
    background-color: var(--secondary-bg-color);
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
}

.attendance-secondary-dashboard-Calendar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.attendance-secondary-dashboard-institute-container h3,
.attendance-secondary-dashboard-standard-container h3,
.attendance-secondary-dashboard-medium-container h3,
.attendance-secondary-dashboard-division-container h3,
.attendance-secondary-dashboard-Calendar-container h3 {
    font-size: 1.2vw;
    font-weight: 400;
}

.Attendance_Reset_button_style {
    padding: 0.1vw 0.95vw;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
    color: var(--primary-color);
}

.AttendanceStyles {
    width: 100%;
    font-size: 1.2vw;
    padding: 0.6vw;
    border-radius: 0.25vw;
    outline: none;
    transition: 0.3s ease-out;
}

.attendance-reset-button-container {
    margin-right: 1vw;
}

// Filter Section ends

// Search Section Starts

.Attendance_Search_section_parent_container {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-direction: row;
}

.Attendance_Search_main_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 0.7vw 0;
}

.attendance-secondary-search_bar_container {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0.7vw 1vw;
    width: 100%;
    border-radius: 0.3vw;
    transition: transform 0.2s, box-shadow 0.2s;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw);
    //margin-right: vw;
}
.sort_dropdown_container{
    margin-left: 1vw;
    margin-right: 1vw;
}
.status_dropdown_container{
    margin-right: 1vw;
    width: 15%;
}
.attendance_button_container{
    display: flex;
    margin-top: 0.15vw;
    //flex-direction: row;
}


.Attendance_search_icon {
    padding: 0 1.3vw;
    display: flex;
    align-items: center;
    color: var(--primary-border-color);
}

.Attendance_search_input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 1.3vw;
    font-weight: 500;
    background: transparent; // Translucent background
    // backdrop-filter: blur(0.1vw); // Frosted glass blur effect
    // -webkit-backdrop-filter: blur(0.1vw); // Webkit support for older browsers
}

.Attendance_search_input::placeholder {
    color: var(--primary-text-color);
    font-size: 1.3vw;
    font-weight: 500;
}


// Search Section ends

.dashboard-student-list-loader {
    width: 100%;
    height: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-student-list-loader div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-student-list-loader .spinner {
    font-size: 2.4vw;
    margin-left: unset;
}

.dashboard-student-list-loader p {
    font-size: 1vw;
    margin-top: 0.4vw;
}

@media only screen and(max-width:$mobileMaxWidth) {

    .Attendance_Section_conatainer {
        padding: 4vw;
        margin: 0 auto;
        width: 100%;

    }

    .Attendance_main_container {
        border: 0.2vw solid rgba(255, 255, 255, 0.2);
        border-radius: 4vw;
        padding: 1vw;
    }

    .Attendance_Pie_Chart_Parent_Container {
        display: flex;
        width: 100%;
        border-radius: 1vw;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2vw;
    }

    .Attendance_pie_Chart_Left_Container {
        padding: 1vw 0 1vw 2vw;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .Attendance_pie_Chart_Right_Container {
        width: 100%;

    }

    .Attendance_Pie_Chart_Contianer {
        margin: 0.5vw 5vw 0.5vw 5vw;

    }

    .Attendance_filter_section_parent_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 1vw 0;
    }

    .Attendance_Total_Students_container,
    .Attendance_Pass_Out_Students_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
    }

    .Attendance_Pass_Out_Students_container {
        margin-top: 1vw;
    }

    .Attendance_Total_Students_container h3,
    .Attendance_Pass_Out_Students_container h3 {
        color: var(--primary-text-color);
        font-size: 4.5vw;
        font-weight: 500;
    }

    div.Attendance_Pie_Chart_Contianer>div {
        width: 80vw !important;
        height: 45vw !important;
        margin: 0 0.5vw;
    }

    .attendance-secondary-dashboard-institute-container,
    .attendance-secondary-dashboard-standard-container,
    .attendance-secondary-dashboard-medium-container,
    .attendance-secondary-dashboard-division-container,
    .attendance-secondary-dashboard-Calendar-container {
        padding: 0.4vw 0.4vw 0.4vw 0.8vw;
        border-radius: 1vw;
        width: 100%;
        background-color: var(--secondary-bg-color);
        text-align: center;
        transition: transform 0.2s, box-shadow 0.2s;
        background: rgba(255, 255, 255, 0.1); // Translucent background
        border: 0.2vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
    }

    .attendance-secondary-dashboard-institute-container h3,
    .attendance-secondary-dashboard-standard-container h3,
    .attendance-secondary-dashboard-medium-container h3,
    .attendance-secondary-dashboard-division-container h3,
    .attendance-secondary-dashboard-Calendar-container h3 {
        font-size: 5vw;
        font-weight: 400;
    }

    .AttendanceStyles {
        width: 100%;
        font-size: 5vw;
        padding: 3vw;
        border-radius: 1vw;
        outline: none;
        transition: 0.3s ease-out;
    }

    .dashboard-student-list-loader {
        width: 100%;
        height: 20vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dashboard-student-list-loader div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dashboard-student-list-loader .spinner {
        font-size: 6vw;
        margin-left: unset;
    }

    .dashboard-student-list-loader p {
        font-size: 4.5vw;
        margin-top: 0.4vw;
    }

    .Attendance_search_icon {
        padding: 0 1.3vw;
        display: flex;
        align-items: center;
        color: var(--primary-border-color);
    }

    .Attendance_search_icon svg {
        font-size: 5vw;
    }

    .Attendance_search_input {
        border: none;
        outline: none;
        flex: 1;
        font-size: 6vw;
        font-weight: 500;
        background: transparent; // Translucent background
        // backdrop-filter: blur(0.1vw); // Frosted glass blur effect
        // -webkit-backdrop-filter: blur(0.1vw); // Webkit support for older browsers
    }

    .Attendance_search_input::placeholder {
        color: var(--primary-text-color);
        font-size: 5vw;
        font-weight: 500;
    }
    .Attendance_Search_section_parent_container {
        display: flex;
        align-content: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .Attendance_Search_main_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 2vw 0 0.7vw 0;
    }
    .sort_dropdown_container{
        margin-top: 1.5vw;
        width: 100%;
        align-items: center;
    }
    .status_dropdown_container{
        margin-top: 2vw;
        width: 100%;
        
    }
    .attendance_button_container{
        margin-top: 0.4vw;
        //flex-direction: row;
    }
    .apply_button_container {
        margin-right: 1vw;
        width: 45%;
        padding-left: 10vw;
    }
    .attendance_button_container{
        display: flex;
        margin-top: 0.15vw;
        justify-content: space-around;
        width: 100%;

    }
    
}