@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.Attendance_Section_conatainer {
    padding: 2vw 6vw;
    margin: 0 auto;
    background: radial-gradient(circle at 30% 30%, #FDE7F3 0, #EAE9FF 46%, #DFFFEE 100%);
    min-height: 100vh;
    /* Ensures it stretches to the viewport and content height */
    background-repeat: no-repeat;
    background-size: cover;
}


.Attendance_Total_Students_container,
.Attendance_Pass_Out_Students_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.Attendance_Pass_Out_Students_container {
    margin-top: 1.3vw;
}

.Attendance_Total_Students_container h3,
.Attendance_Pass_Out_Students_container h3 {
    // color: var(--primary-text-color);
    font-size: 1.5vw;
    font-weight: 500;
}

.Attendance_Total_students_number {
    color: var(--primary-color) !important;
}

.Attendance_present_students_number {
    color: var(--paid_pie_chart-color) !important;
}

.Attendance_absent_students_number {
    color: var(--over-due-pie_chart-color) !important;
}

.Attendance_unmarked_students_number {
    color: #ffff36 !important;
}

.Attendance_Pie_Chart_Parent_Container {
    // border: 0.1vw solid var(--primary-text-color);
    display: flex;
    width: 100%;
    border-radius: 0.3vw;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
    color: var(--secondary-background-color);
}

.Attendance_pie_Chart_Left_Container {
    padding: 1vw 0 3vw 2vw;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.Attendance_pie_Chart_Right_Container {
    width: 50%;
    // border-left: 0.1vw solid var(--primary-text-color);
}


.Attendance_Pie_Chart_Contianer {
    margin: 0.5vw 0 0.5vw 0;
}

.css-66gjpw-MuiResponsiveChart-container {
    margin: 0 3vw;
}

// Filter Section Starts

.Attendance_filter_section_parent_container {
    display: flex;
    align-items: center;
    margin: 1vw 0;
    justify-content: space-between;
}


.attendance-calendar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 9vw;
    margin-right: 1vw
}

.AttendanceStyles {
    width: 100%;
    font-size: 1.2vw;
    padding: 0.6vw;
    border: 0.1vw solid var(--primary-text-input-field-border-color);
    border-radius: 0.25vw;
    outline: none;
    transition: 0.3s ease-out;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    border: 0.1vw solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
}


.attendance-input-right-icon-container {
    margin-top: 0.3vw;
    position: absolute !important;
    right: 0.7vw !important;
    z-index: 11;
}

.attendance-input-right-icon-container:hover {
    cursor: pointer;
}


.Attendance_Reset_button_style {
    padding: 0.1vw 0.95vw;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
    color: var(--primary-color);

}

.attendance-reset-button-container {
    margin-right: 1vw;
}

// Filter Section ends

// Search Section Starts


.modal-content {
    position: relative;
    padding: 2vw;
}

.close {
    position: absolute;
    top: 0vw;
    right: 0.5vw;
}


// Search Section ends


.dashboard-student-list-loader {
    width: 100%;
    height: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-student-list-loader div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-student-list-loader .spinner {
    font-size: 2.4vw;
    margin-left: unset;
}

.dashboard-student-list-loader p {
    font-size: 1vw;
    margin-top: 0.4vw;
}

.Attendance_dashboard_institue_container {
    width: 34vw;
    margin-right: 1vw;
}

.dashboard_medium_container {
    width: 8vw;
    margin-right: 1vw;
}

.board_container {
    width: 9.5vw;
    margin-right: 1vw;
}

.dashboard_standard_container {
    width: 8vw;
    margin-right: 1vw;
}

.attendance-input-right-icon-container svg {
    width: 1.5vw;
    height: 1.3vw;

}

.attendance-buttons {
    display: flex;
    flex-direction: row;
}


@media only screen and (max-width:$mobileMaxWidth) {

    .Attendance_filter_section_parent_container {
        display: flex;
        align-items: center;
        margin: 1vw 0;
        justify-content: none;
        width: 100%;
    }

    .Dashboard_main_container .common-dropdown-container select {
        width: 100vw;

    }

    .Attendance_dashboard_institue_container {
        width: 100%;
        margin-bottom: 2vw;
        margin-right: 0vw;
    }

    .dashboard_medium_container {
        width: 100%;
        margin-bottom: 2vw;
        margin-right: 0vw;
    }

    .board_container {
        width: 100%;
        margin-bottom: 2vw;
        margin-right: 0vw;
    }

    .dashboard_standard_container {
        width: 100%;
        margin-bottom: 2vw;
        margin-right: 0vw;
    }

    .attendance-calendar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        margin-right: 0vw;
        // font-size: 5vw;
    }

    .AttendanceStyles {
        width: 100%;
        font-size: 4vw;
        padding: 0.6vw;
        border: 0.1vw solid var(--primary-text-input-field-border-color);
        border-radius: 0.25vw;
        outline: none;
        transition: 0.3s ease-out;
        background: rgba(255, 255, 255, 0.1); // Translucent background
        border: 0.1vw solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
    }

    .attendance-input-right-icon-container {
        margin-top: 1vw;
        position: absolute !important;
        right: 1.7vw !important;
        z-index: 11;
    }

    .attendance-input-right-icon-container svg {
        width: 5vw;
        height: 5vw;
    }

    .dashboard-student-list-loader {
        width: 100%;
        height: 50vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dashboard-student-list-loader div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dashboard-student-list-loader .spinner {
        font-size: 4.4vw;
        margin-left: unset;
    }

    .dashboard-student-list-loader p {
        font-size: 4vw;
        margin-top: 0.4vw;
    }

    .Attendance_pie_Chart_Left_Container {
        padding: 1vw 0 3vw 2vw;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .Attendance_Total_Students_container,
    .Attendance_Pass_Out_Students_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
    }

    .Attendance_Pass_Out_Students_container {
        margin-top: 1.5vw;
    }

    .Attendance_Total_Students_container h3,
    .Attendance_Pass_Out_Students_container h3 {
        // color: var(--primary-text-color);
        font-size: 4.5vw;
        font-weight: 500;
    }

    .Attendance_pie_Chart_Right_Container {
        width: 95%;
        //border-left: 0.1vw solid var(--primary-text-color);
    }


    .Attendance_Pie_Chart_Contianer {
        margin: 0.5vw 0 0.5vw 0;
    }

    .css-66gjpw-MuiResponsiveChart-container {
        margin: 0 4.5vw;
    }

    .Attendance_Reset_button_style {
        padding: 0.1vw 0.95vw;
        background: rgba(255, 255, 255, 0.1); // Translucent background
        border: 0.2vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
        color: var(--primary-color);

    }

    .attendance-reset-button-container {
        margin-right: 0vw;
    }

    .apply_button_container {
        margin-left: 45vw;
    }

    .attendance-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 4vw;
        padding-bottom: 0.5vw;

    }



}