@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.admission-secondary-table-container {
    width: 100%;
    margin: 0.5vw 0 0 0;
}

.admission-secondary-table {
    width: 100%;
    border-collapse: collapse;

    th {
        background-color: var(--primary-color);
        color: var(--secondary-text-color);
    }

    th,
    td {
        padding: 0.5vw;
        text-align: center;
        font-size: 1vw;
        font-weight: 500;
        box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.1);
    }

    tbody {
        tr {
            transition: transform 0.2s, box-shadow 0.2s;

            &:hover {
                transform: translateY(-5px);
            }

            &:nth-child(even) td {
                background-color: var(--table-even-row-background-color);
            }

            &:nth-child(odd) td {
                background-color: var(--secondary-text-color);
            }

            td:nth-child(5) {
                min-width: 10vw !important;

                span {
                    cursor: pointer;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre-wrap;
                    min-width: 10vw !important;
                }
            }

            td:nth-child(3),
            td:nth-child(2) {
                width: 7vw;
            }

            td:nth-child(1),
            td:nth-child(6),
            td:nth-child(7) {
                width: 5vw;
            }
        }
    }
}

.temporary-comment-container {
    width: 80%;
    padding: 0.3vw;
    border: 0.1vw solid var(--primary-text-color);
    padding: 0.5vw;
    border-radius: 0.3vw;
    outline: none;
    font-size: 1vw;
}

.temporary-comment-container,
.limited-lines {
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    opacity: 1;
}

.hidden {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.Comment-save-edit-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0.5vw;

    a {
        text-decoration: none;
    }
}

.info-icon {
    color: var(--primary-color);
    font-size: 1.5vw;
}

.pdf-icon {
    color: var(--primary-error-color);
    font-size: 1.5vw;
}

.attendance-secondary-link-text {
    text-decoration: none !important;
    color: var(--primary-color);
    cursor: pointer;
}


.showing_index p {
    font-size: 1.1vw;
}

.Attendance_secondary-pagination {
    margin: 0.5vw 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Attendance_secondary-pagination_container p {
    font-size: 1.1vw;
}

.Attendance_Secondary-pagination_controls {
    display: flex;
    font-size: 1.1vw;
    align-items: center;

    .Attendance_Secondary-display_container {
        display: flex;
        margin-left: 1.1vw;

        input {
            padding: 0 0 0 0.4vw;
            font-size: 1.1vw;
        }
    }

    .Attendance_Secondary-extra_space {
        margin-left: 0.7vw;
    }

    .Attendance_Secondary-arrows_container {
        display: flex;
        align-items: center;
    }

    .Attendance_Secondary-left_arrow_btn_container,
    .Attendance_Secondary-right_arrow_btn_container {
        border: 0.1vw solid var(--primary-border-color);
        padding: 0 0.4vw;
        font-weight: bold;
        cursor: pointer;
    }

    .Attendance_Secondary-left_arrow_active,
    .Attendance_Secondary-right_arrow_active {
        border: 0.1vw solid var(--primary-color);
        padding: 0 0.4vw;
        font-weight: bold;
        color: var(--primary-color);
        cursor: pointer;
        margin-left: 0.5vw;
    }

    .Attendance_Secondary-number_pages_parent {
        display: flex;
        align-items: center;
    }

    .numbers_contianer {
        padding: 0vw 0.6vw;
        cursor: pointer;
    }

    .current_page_active {
        background-color: var(--primary-color);
        color: var(--secondary-text-color);
        padding: 0.06vw 0.6vw;
    }
}

.custom-checkbox {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 0.1vw;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-top: 0.5vw;


    &.checked {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--secondary-text-color);
        position: relative;
    }

    &.unchecked {
        background-color: var(--primary-error-color);
        border-color: var(--primary-error-color);
        color: var(--secondary-text-color);
        position: relative;
    }

    &.checked:after {
        content: '\2713'; // Unicode character for checkmark
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5vw;
        font-weight: 500;
        color: var(--secondary-text-color);
    }

    &.unchecked:after {
        content: '\2717'; // Unicode character for cross
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5vw;
        font-weight: 500;
        color: var(--secondary-text-color);
    }

    :disabled {
        cursor: not-allowed;
    }

}

.secondary-screen-butttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.7vw;
}

@media only screen and(max-width:$mobileMaxWidth) {
    .admission-secondary-table-container {
        width: 100%;
        margin: 2vw 0 0 0;

    }

    .admission-secondary-table {
        width: 100%;
        border-collapse: collapse;
        overflow: auto;
        display: inherit;


        th {
            background-color: var(--primary-color);
            color: var(--secondary-text-color);
        }

        th,
        td {
            padding: 2vw;
            text-align: center;
            font-size: 4.5vw;
            font-weight: 500;
            box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.1);
        }

        tbody {
            tr {
                transition: transform 0.2s, box-shadow 0.2s;

                &:hover {
                    transform: translateY(-5px);
                }

                &:nth-child(even) td {
                    background-color: var(--table-even-row-background-color);
                }

                &:nth-child(odd) td {
                    background-color: var(--secondary-text-color);
                }

                td:nth-child(5) {
                    min-width: 10vw !important;

                    span {
                        cursor: pointer;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: pre-wrap;
                        min-width: 10vw !important;
                    }
                }

                td:nth-child(3),
                td:nth-child(2) {
                    width: 7vw;
                }

                td:nth-child(1),
                td:nth-child(6),
                td:nth-child(7) {
                    width: 5vw;
                }
            }
        }
    }

    .custom-checkbox {
        width: 4.5vw;
        height: 4.5vw;
        border-radius: 0.2vw;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-top: 0.5vw;


        &.checked {
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--secondary-text-color);
            position: relative;
        }

        &.unchecked {
            background-color: var(--primary-error-color);
            border-color: var(--primary-error-color);
            color: var(--secondary-text-color);
            position: relative;
        }

        &.checked:after {
            content: '\2713'; // Unicode character for checkmark
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 4.5vw;
            font-weight: 500;
            color: var(--secondary-text-color);
        }

        &.unchecked:after {
            content: '\2717'; // Unicode character for cross
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 4.5vw;
            font-weight: 500;
            color: var(--secondary-text-color);
        }

        :disabled {
            cursor: not-allowed;
        }

    }

    .temporary-comment-container {
        width: 90%;
        padding: 0.3vw;
        border: 0.2vw solid var(--primary-text-color);
        padding: 0.5vw;
        border-radius: 0.5vw;
        outline: none;
        font-size: 3vw;
    }

    .temporary-comment-container,
    .limited-lines {
        transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
        opacity: 1;
    }

    .Comment-save-edit-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        // justify-content: space-evenly;
        margin-top: 0.5vw;

        a {
            text-decoration: none;
        }
    }

    .showing_index p {
        font-size: 4vw;
    }

    .Attendance_secondary-pagination {
        margin: 0.5vw 0 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .Attendance_secondary-pagination_container p {
        font-size: 5.5vw;
    }

    .Attendance_Secondary-pagination_controls {
        display: flex;
        font-size: 6vw;
        align-items: flex-start;
        flex-direction: column;

        .Attendance_Secondary-display_container {
            display: flex;
            margin-right: 1.5vw;
            margin-top: 3vw;
            margin-bottom: 2vw;

            input {
                padding: 0 0 0 0.5vw;
                font-size: 6vw;
            }
        }

        .Attendance_Secondary-extra_space {
            margin-left: 1vw;
        }

        .Attendance_Secondary-arrows_container {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 7.8vw;
            margin-top: 2vw;
        }

        .Attendance_Secondary-left_arrow_btn_container,
        .Attendance_Secondary-right_arrow_btn_container {
            border: 0.2vw solid var(--primary-border-color);
            padding: 0 0.8vw;
            font-weight: bold;
            cursor: pointer;
            width: 10vw;
            display: flex;
            justify-content: center;
            padding: 0 1vw;
        }

        .Attendance_Secondary-left_arrow_active,
        .Attendance_Secondary-right_arrow_active {
            border: 0.2vw solid var(--primary-color);
            padding: 0 0.8vw;
            font-weight: bold;
            color: var(--primary-color);
            cursor: pointer;
            display: flex;
            width: 10vw;
            padding: 0 1vw;
            justify-content: center;

        }

        .Attendance_Secondary-number_pages_parent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1 1;
        }

        .numbers_contianer {
            padding: 0vw 3vw;
            cursor: pointer;
            justify-content: space-between;
        }

        .current_page_active {
            background-color: var(--primary-color);
            color: var(--secondary-text-color);
            padding: 0vw 4vw;
            font-size: 8vw;
            width: 10vw;
        }
    }
}