@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.our-services-main-container {
    width: 100%;
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.our-services-heading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-services-heading-container p {
    font-size: 2.6vw;
    font-weight: 600;
}

.our-services-content-container {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1vw;
}

.our-services-left-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-services-right-container {
    width: 50%;
}

.our-services-image {
    width: 25vw;
    height: auto;
}

.our-services-right-container .our-services-heading-text {
    font-weight: 500;
    font-size: 2vw;
    margin-left: 2.5vw;
}

.our-services-right-container ul li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1vw;
}

.our-services-right-container ul li p {
    margin-left: 1vw;
    font-size: 1.5vw;
    font-weight: 400;
}

.our-services-right-container ul li .our-services-check-icon {
    font-size: 2vw;
    color: var(--secondary-color);
    margin-top: 0.3vw;
}

@media only screen and (max-width: $mobileMaxWidth) {
    .our-services-main-container {
        margin-top: 6vw;
    }

    .our-services-heading-container p {
        font-size: 7vw;
    }

    .our-services-content-container {
        flex-direction: column;
        width: 90%;
    }

    .our-services-left-container {
        width: 100%;
        margin-top: 4vw;
    }

    .our-services-image {
        width: 60vw;
    }

    .our-services-right-container {
        width: 100%;
        margin-top: 4vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .our-services-right-container .our-services-heading-text {
        margin-left: unset;
        font-size: 6vw;
    }

    .our-services-right-container ul {
        width: 80%;
    }

    .our-services-right-container ul li .our-services-check-icon {
        font-size: 9vw;
        margin-top: unset;
    }

    .our-services-right-container ul li p {
        font-size: 4vw;
        margin-left: 3vw;
        margin-top: 1.25vw;
    }
}