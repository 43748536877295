@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';


.installment_header_container h1 {
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 0.5vw;
}

.total_fees_discount_fees_parent_contianer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1vw 0;
}

.total_fees_container,
.discounted_container {
    border: 0.2vw solid var(--primary-text-color);
    padding: 0.5vw 1vw;
}

.link-text.disabled-text {
    cursor: not-allowed;
    opacity: 0.5;
}

.total_fees_container h1,
.discounted_container h1 {
    font-size: 2vw;
    font-weight: 400;
}

/* New styles for the table */

.fees_table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.5vw;
    margin-bottom: 4vw;
}

.fees_table th,
.fees_table td {
    border: 0.1vw solid var(--primary-text-color);
    padding: 0.7vw;
    text-align: center;
}

.fees_table th {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);
}

.fees_table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.fees_table td button {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);
    border-radius: 0.5vw;
    border: none;
    padding: 0.7vw 1vw;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fees_table td button:hover {
    color: var(--primary-color);
    background-color: var(--secondary-text-color);
    // border: 0.1vw solid var(--primary-color);
}

.add_fees_button_container {
    margin-left: 1.1vw;
}

.fees_tab_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.submit_button_for_add_discount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5vw;
    padding: 0 1.5vw;
}

.installment_mobile_header_container {
 display: none;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .fees_tab_buttons_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .total_fees_discount_fees_parent_contianer {
        display: flex;
        //justify-content: none;
        align-items: flex-start;
        flex-direction: column;
        margin: 1vw 0;
    
    }
    .total_fees_container,
.discounted_container {
    border: none;
    padding: 0.5vw 1vw;
}

.total_fees_container h1,
.discounted_container h1 {
    font-size: 4.5vw;
    font-weight: 500;
}

.installment_header_container h1 {
   display: none;
}
.installment_mobile_header_container {
    display: flex;
    align-items:center;
   justify-content:center;
   border-bottom: 0.2vw solid var(--primary-text-color);
}
.installment_mobile_header_container h1 {
   font-size: 4vw;
   font-weight: 500;
   margin-top: 1vw;
}

.fees_table {
    width: 100%;
    overflow-x: auto;
    display:inherit;
    border-collapse: collapse;
    margin-top: 1.5vw;
    
}


} 