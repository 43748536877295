@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';


.attendance-input-field-outer-container {
    position: relative;
    margin: 0vw !important;
    padding: 0vw !important;
    width: 75% !important;

}

.custom-input {
    width: 100%;
    position: relative;
}


.attendance-custom-input {
    width: 85% !important;
    position: relative;
}

.custom-input input {
    width: 100%;
    font-size: 1.2vw;
    padding: 0.6vw;
    border: 0.1vw solid var(--primary-text-input-field-border-color);
    border-radius: 0.25vw;
    outline: none;
    transition: 0.3s ease-out;
}

.AttendanceStyles {
    // width: 75% !important;
    font-size: 1.2vw;
    padding: 0.6vw 0.5vw 0.5vw 0.5vw !important;
    border: 0.1vw solid var(--primary-border-color);
    border-radius: 0.25vw;
    outline: none;
    transition: 0.3s ease-out;
}


.input-label {
    position: absolute;
    top: 1.1vw;
    left: 0.8vw;
    transform: translateY(-0.6vw);
    pointer-events: none;
    transition: 0.3s ease-out;
    font-size: 1.1vw;
    font-weight: 300;
    color: var(--primary-placeholder-color);
}

.custom-input.input-error input {
    border-color: var(--primary-error-color);
}

.custom-input.input-focused .input-label,
.custom-input input:not(:placeholder-shown)+.input-label {
    top: 0;
    font-size: 0.8vw;
    color: var(--primary-color);
    // background-color: lavenderblush;
    background-color: var(--secondary-background-color);

}

.custom-input.input-focused.input-error .input-label,
.custom-input.input-error input:not(:placeholder-shown)+.input-label {
    color: var(--primary-error-color);
}

.custom-input.input-focused input {
    border-color: var(--primary-color);
}

.custom-input.input-focused.input-error input {
    border-color: var(--primary-error-color);
}


.input-left-label-container {
    position: absolute;
    top: 0.65vw;
    left: 0.8vw;
}

.input-left-label-container :first-child {
    font-size: 1.3vw;
}

.input-left-label-container+.input-label {
    left: 2.8vw;
}

.input-left-label-container+.input-label~input {
    padding-left: 2.8vw;
}

.input-right-icon-container {
    position: absolute;
    top: 0.7vw;
    right: 0.5vw;
}

.input-right-icon-container:hover,
.attendance-input-right-icon-container:hover {
    cursor: pointer;
}

.input-right-icon-container :first-child {
    font-size: 1.3vw;
}

.input-right-icon-container+input {
    padding-right: 2.3vw;
}


.input-right-icon-container+.input-error-container {
    right: 2.4vw;
}

// .input-right-icon-container+.input-error-container:hover {
//     cursor: pointer;
// }
.input-error-container:hover {
    cursor: pointer;
}

.input-right-icon-container+.input-error-container+input {
    padding-right: 4.1vw;
}

.input-country-code-label {
    position: absolute;
    top: 0.4vw;
    left: 0.8vw;
}

.input-country-code-label :first-child {
    font-size: 1.2vw;
}

.input-country-code-label+.input-label {
    left: 3.1vw;
}

.input-country-code-label+.input-label~input {
    //~ target any sibling with input tag
    padding-left: 3.1vw;
}

.input-error-container {
    position: absolute;
    top: 0.75vw;
    right: 0.5vw;
}

.input-error-container :first-child {
    font-size: 1.3vw;
    color: var(--primary-error-color);
}

.input-error-container+input {
    //+ target immediate sibling only
    padding-right: 2.3vw;
}

.input-field-outer-container {
    position: relative;
    margin-bottom: 0.8vw;
}



// .login-form-error-container+.login-form .input-field-outer-container:first-child {
//     margin-top: 0.3vw;
// }

.login-form-heading-container+.login-form .input-field-outer-container:first-child {
    margin-top: 2.6vw;
}

.input-field-outer-container .input-error-text-container {
    position: absolute;
    top: -1.5vw;
    right: -1.15vw;
    background-color: var(--secondary-background-color);
    border: 0.1vw solid var(--primary-error-color);
    padding: 0vw 0.15vw;
    border-radius: 0.25vw;
    max-width: 100%;
    z-index: 9999;
    // display: none;
}

.input-field-outer-container .input-error-text-container::after {
    content: '';
    position: absolute;
    top: 1.6vw;
    right: 1.65vw;
    width: 0;
    height: 0;
    border-left: 0.6vw solid transparent;
    border-right: 0.6vw solid transparent;
    border-top: 0.6vw solid var(--primary-error-color);
}

.input-field-outer-container .input-error-text-container.input-right-icon-added::after {
    right: 3.55vw;
}

.input-field-outer-container .input-error-text-container p {
    font-size: 1vw;
    font-weight: 300;
    color: var(--primary-error-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

@media only screen and (max-width:1100px) {
    .input-left-label-container {
        top: 0.55vw;
    }

    .input-error-container {
        top: 0.5vw;
    }

    .input-right-icon-container {
        top: 0.55vw;
    }
}

@media only screen and (max-width: 900px) {

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: 0.1vw;
    }

    .input-label {
        top: 1.25vw;
    }

    .input-error-container {
        top: 0.25vw;
    }

    .input-left-label-container {
        top: 0.4vw;
    }

    .input-left-label-container {
        top: 0.4vw;
    }

    .input-right-icon-container {
        top: 0.25vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -1.7vw;
    }
}

@media only screen and (max-width:846px) {

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: 0.2vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -1.45vw;
    }

    .input-field-outer-container .input-error-text-container::after {
        right: 1.75vw;
    }

    .input-error-container {
        right: 0.55vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -1.45vw;
    }

    .input-right-icon-container {
        right: 0.55vw;
    }

    .input-right-icon-container+.input-error-container {
        right: 2.5vw;
    }

    .input-label {
        top: 1.5vw;
    }
}

@media only screen and (max-width: 780px) {
    .input-label {
        top: 1.7vw;
    }

    .input-error-container {
        right: 0.6vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -1.1vw;
    }

    .input-left-label-container {
        top: 0.35vw;
    }

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: 0.5vw;
    }

    .input-right-icon-container {
        right: 0.6vw;
    }
}

@media only screen and (max-width:700px) {
    .input-left-label-container {
        top: 0.4vw;
    }

    .input-label {
        top: 2vw;
    }

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: 0.7vw;
    }

    .input-error-container {
        top: 0.25vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -0.9vw;
    }

    .input-right-icon-container {
        top: 0.25vw;
    }
}

@media only screen and (max-width: 624px) {
    .input-label {
        top: 2.3vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -0.5vw;
    }

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: 0.95vw;
    }
}

@media only screen and (max-width:560px) {
    .input-label {
        top: 2.65vw;
    }

    .input-field-outer-container .input-error-text-container {
        top: -0.1vw;
    }

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: 1.35vw;
    }
}

@media only screen and (max-width:$mobileMaxWidth) {
    .custom-input {
        width: 100%;
        position: relative;
    }

    .login-form-heading-container+.login-form .input-field-outer-container:first-child {
        margin-top: 7.5vw;
    }

    .custom-input input {
        width: 100%;
        font-size: 4.5vw;
        padding: 3vw;
        border: 0.2vw solid var(--primary-text-input-field-border-color);
        border-radius: 1vw;
        outline: none;
        transition: 0.3s ease-out;
        margin-bottom: 2vw;


    }

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        top: -1.7vw;
    }

    .custom-input.input-focused .input-label,
    .custom-input input:not(:placeholder-shown)+.input-label {
        font-size: 3.5vw;
        color: var(--primary-color);
        background-color: var(--secondary-background-color);
    }

    .input-label {
        position: absolute;
        top: 3vw;
        left: 2%;
        transform: translateY(-0.6vw);
        pointer-events: none;
        transition: 0.3s ease-out;
        font-size: 4vw;
        font-weight: 300;
        color: var(--primary-placeholder-color);
    }

    .input-left-label-container {
        position: absolute;
        top: 2.3vw;
        left: 2vw;
    }

    .input-left-label-container :first-child {
        font-size: 6vw;
    }

    .input-left-label-container+.input-label {
        left: 10vw;
    }

    .input-left-label-container+.input-label~input {
        padding-left: 10vw;
    }

    .input-country-code-label {
        position: absolute;
        top: 2.6vw;
        left: 2.8vw;
    }

    .input-country-code-label :first-child {
        font-size: 4.5vw;
    }

    .input-country-code-label+.input-label {
        left: 12.5vw;
    }

    .input-country-code-label+.input-label~input {
        //~ target any sibling with input tag
        padding-left: 12vw;
        margin-top: 0.4vw;
    }

    .input-right-icon-container {
        position: absolute;
        top: 3.4vw;
        right: 2.5vw;
    }

    .input-right-icon-container :first-child {
        font-size: 5vw;
    }

    .input-right-icon-container+.input-error-container {
        right: 9vw;
    }

    .input-error-container {
        position: absolute;
        top: 3.4vw;
        right: 3vw;
    }

    .input-error-container :first-child {
        font-size: 4.5vw;
        color: var(--primary-error-color);
    }



    .input-field-outer-container .input-error-text-container {
        position: absolute;
        top: -2.8vw;
        right: -3.2vw;
        background-color: var(--secondary-background-color);
        border: 0.1vw solid var(--primary-error-color);
        padding: 0vw 0.15vw;
        border-radius: 0.5vw;
        max-width: 100%;
        z-index: 9999;
        // display: none;
    }

    .input-field-outer-container .input-error-text-container::after {
        content: '';
        position: absolute;
        top: 4.9vw;
        right: 7vw;
        width: 0;
        height: 0;
        border-left: 1.2vw solid transparent;
        border-right: 1.2vw solid transparent;
        border-top: 1.2vw solid var(--primary-error-color);
    }

    .input-field-outer-container .input-error-text-container.input-right-icon-added::after {
        right: 13.3vw;
    }

    .input-field-outer-container .input-error-text-container p {
        font-size: 3vw;
        font-weight: 400;
        color: var(--primary-error-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

}