@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils//Styles/StylesConfig.scss';

.login-modal-outer-container {
    width: 100%;
    height: 100vh;
    background-color: var(--modal-background-color);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.login-modal-main-container {
    width: 60%;
    border: 0.1vw solid var(--primary-border-color);
    background-color: var(--primary-background-color);
    border-radius: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.login-left-section {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-right-section {
    width: 45%;
    background-color: var(--secondary-background-color);
    border-radius: 1vw;
    border: 0.1vw solid var(--primary-border-color);
    border-top-width: 0;
    border-bottom-width: 0;
    border-right-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1vw 0;
}

.login-modal-main-container .login-left-section img {
    width: 30vw;
    height: auto;
}

.login-right-section .login-modal-close-container {
    width: 92%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-right-section .login-form-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.login-form-container .login-logo-container img {
    width: 20vw;
    height: auto;
}

.login-modal-close-container .login-modal-close-button {
    background-color: var(--primary-inactive-background-color);
    width: 1.35vw;
    height: 1.35vw;
    border-radius: 1.35vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-modal-close-container .login-modal-close-button:hover {
    cursor: pointer;
}

.login-modal-close-container .login-modal-close-button .login-modal-close-icon {
    font-size: 0.95vw;
}

.login-form-heading-container p {
    font-weight: 500;
    font-size: 1.65vw;
}

.login-right-section .login-form {
    width: 100%;
}

.login-form .login-form-login-button-container {
    margin-top: 0.4vw;
}

.login-remember-forgot-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5vw;
}

.login-remember-forgot-container .login-remember-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.login-remember-forgot-container .login-remember-container .remember-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 0.2vw;
    width: 0.8vw;
    height: 0.8vw;
    // margin-top: 0.15vw;
}

.login-remember-forgot-container .login-remember-container .remember-checkbox:hover {
    cursor: pointer;
}

.login-remember-forgot-container .login-remember-container .remember-checkbox.disable:hover {
    cursor: not-allowed;
}

.login-remember-forgot-container .login-remember-container .remember-checkbox :first-child {
    font-size: 0.5vw;
}

.login-remember-forgot-container .login-remember-container .login-remember-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.3vw;
    margin-top: 0.1vw;
}

.login-remember-forgot-container .login-remember-container .login-remember-text p {
    font-size: 0.9vw;
    font-weight: 300;
}

.login-remember-forgot-container .login-forgot-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-remember-forgot-container .login-forgot-container p {
    font-size: 0.9vw;
    font-weight: 300;
}

.login-form .login-register-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
}

.login-form .login-register-text p {
    font-size: 1vw;
    font-weight: 400;
}

.login-form .login-contact-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5vw;
}

.login-form .login-contact-text p {
    font-size: 0.9vw;
    font-weight: 300;
}

.login-form-error-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0.1vw solid var(--primary-error-color);
    padding: 0 0.2vw;
    border-radius: 0.25vw;
    max-width: 100%;
    margin-bottom: 0.8vw;
}

.login-form-error-container :first-child {
    margin-right: 0.25vw;
    color: var(--primary-error-color);
    margin-top: 0.15vw;
    font-size: 1vw;
}

.login-form-error-container p {
    font-size: 0.9vw;
    color: var(--primary-error-color);
    flex: 1;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

.login-soft-lock-container {
    width: 100%;
}

.login-soft-lock-container .back-to-login-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5vw;
}

.login-soft-lock-container .back-to-login-button-container .back-to-login-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-color);
}

.login-soft-lock-container .back-to-login-button-container .back-to-login-button:hover {
    cursor: pointer;
}

.login-soft-lock-container .back-to-login-button-container .back-to-login-button :first-child {
    font-size: 0.8vw;
    margin-right: 0.25vw;
}

.login-soft-lock-container .back-to-login-button-container .back-to-login-button p {
    text-decoration: underline;
    font-size: 0.8vw;
}

.login-soft-lock-container .soft-lock-warning-text-container {
    margin-top: 1.6vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.login-soft-lock-container .soft-lock-warning-text-container .soft-lock-warning-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.login-soft-lock-container .soft-lock-warning-text-container .soft-lock-warning-container :first-child {
    font-size: 2vw;
}

.login-soft-lock-container .soft-lock-warning-text-container .soft-lock-warning-container p {
    font-size: 1.8vw;
    font-weight: 500;
}

.login-soft-lock-container .soft-lock-warning-text-container .soft-lock-text {
    width: 16vw;
    margin-top: 0.7vw;
}

.login-soft-lock-container .soft-lock-warning-text-container .soft-lock-text p {
    font-size: 1.2vw;
    text-align: center;
    font-weight: 400;
}

.login-soft-lock-container .login-or-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 1vw 0;
}


.login-soft-lock-container .login-or-line p {
    font-size: 1.2vw;
    font-weight: 500;
}

.login-soft-lock-container .login-or-line .or-line {
    flex: 1;
    // border-bottom-width: 0.25vw;
    // border-color: var(--primary-text-color);
    height: 0.05vw;
    background-color: var(--primary-text-color);
}

.login-soft-lock-container .login-reset-password-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-soft-lock-container .login-reset-password-container p {
    font-size: 1vw;
    font-weight: 400;
}

.login-soft-lock-container .login-contact-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2.4vw;
}

.login-soft-lock-container .login-contact-text p {
    font-size: 0.8vw;
}
@media only screen and (max-width:$mobileMaxWidth){
    .login-modal-main-container .login-left-section img{
        display:none;
    }


    .login-left-section {
        display: none;
    }

    .login-right-section {
        width: 100%;
        background-color: var(--secondary-background-color);
        border-radius: 3vw;
        border: 0.1vw solid var(--primary-border-color);
        border-top-width: 0;
        border-bottom-width: 0;
        border-right-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3vw 0;
    }

    .login-modal-main-container {
        width: 85%;
        border: 0.1vw solid var(--primary-border-color);
        background-color: var(--primary-background-color);
        border-radius: 3vw;
        display: flex;
        flex-direction: row;    
        justify-content: flex-start;
        align-items: center;
        
    }

    .login-form-container .login-logo-container img {
        width: 60vw;
        height: auto;
    }

    .login-form-heading-container p {
        font-weight: 500;
        font-size: 6vw;
        margin-bottom: 1vw;
    }

    .login-modal-close-container .login-modal-close-button {
        background-color: var(--primary-inactive-background-color);
        width: 5vw;
        height: 5vw;
        border-radius: 3vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-modal-close-container .login-modal-close-button .login-modal-close-icon {
        font-size: 4vw;
    }

    .login-right-section .login-form-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:3.5vw;
       
    }

    .login-remember-forgot-container .login-remember-container .remember-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.26vw solid var(--primary-text-color);
        border-radius: 1vw;
        width: 3.1vw;
        height: 3.1vw;
    }

    .login-remember-forgot-container .login-remember-container .login-remember-text p {
        font-size: 3.5vw;
        font-weight: 300;
        padding-left: 0.5vw;
    } 

    .login-remember-forgot-container .login-remember-container .remember-checkbox :first-child {
        font-size: 2vw;
    }


    .login-remember-forgot-container .login-forgot-container p {
        font-size: 3.5vw;
        font-weight: 300;
    }

    .login-form .login-register-text p {
        font-size: 3.9vw;
        font-weight: 400;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }

    .login-form .login-contact-text p {
        font-size: 3.5vw;
        font-weight: 300;
    }

    .login-soft-lock-container .back-to-login-button-container .back-to-login-button :first-child {
        font-size: 3.5vw;
        margin-right: 0.5vw;
    }

    .login-soft-lock-container .back-to-login-button-container .back-to-login-button p {
        text-decoration: underline;
        font-size: 3.5vw;
    }

    .login-soft-lock-container .soft-lock-warning-text-container {
        margin-top: 5vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .login-soft-lock-container .soft-lock-warning-text-container .soft-lock-warning-container :first-child {
        font-size: 7vw;
        margin-bottom: 1vw;
    }

    .login-soft-lock-container .soft-lock-warning-text-container .soft-lock-warning-container p {
        font-size: 6vw;
        font-weight: 500;
    }

    .login-soft-lock-container .soft-lock-warning-text-container .soft-lock-text {
        width: 70vw;
        margin-top: 0.7vw;
    }

    .login-soft-lock-container .soft-lock-warning-text-container .soft-lock-text p {
        font-size: 4.5vw;
        text-align: center;
        font-weight: 400;
    }

    .login-soft-lock-container .login-or-line {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 3vw 0;
    }
    
    
    .login-soft-lock-container .login-or-line p {
        font-size: 4.5vw;
        font-weight: 500;
    }

    .login-soft-lock-container .login-reset-password-container p {
        font-size: 4vw;
        font-weight: 400;
    }

    .login-soft-lock-container .login-contact-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 8vw;
    }
    
    .login-soft-lock-container .login-contact-text p {
        font-size: 3.5vw;
    }

    .login-form-error-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        border: 0.1vw solid var(--primary-error-color);
        padding: 0 1.3vw;
        border-radius: 1vw;
        max-width: 100%;
        margin-top: 0.4vw;
        margin-bottom: 3vw;

    }
    
    .login-form-error-container :first-child {
        margin-right: 1vw;
        color: var(--primary-error-color);
        margin-top: 0.7vw;
        font-size: 3.8vw;
    }
    
    .login-form-error-container p {
        font-size: 3.7vw;
        color: var(--primary-error-color);
        flex: 1;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
}
