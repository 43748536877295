@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';


.NewRegistrationForm_main_banner_container {
    width: 100%;
    background-color: var(--secondary-text-color);
    padding: 2vw;
    margin: 0 auto;
}

.NewRegistrationForm_main-banner-content-container {
    border: 0.1vw solid var(--primary-border-color);
    width: 82%;
    margin: 0 auto;
    border-radius: 1vw;
    background-color: var(--primary-background-color);
    display: flex;
}

.registration_inner_left_banner_container {
    width: 65%;
}

.registration_inner_right_banner_container {
    border: 0.1vw solid var(--secondary-border-color);
    border-radius: 1vw;
    width: 35%;
    background-color: var(--secondary-background-color);
    padding: 1vw;

}

.otp-verified-text {
    color: var(--main-banner-secondary-color);
}


.registration_banner_img_container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.registration_banner_img_container img {
    width: 30vw;
    margin-top: 32px;
}

.paragraph_points_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1vw 0 2vw 2vw;
}

.paragraph_points_wrapper {
    display: flex;
    align-items: center;
}

.check-icon {
    color: var(--secondary-color);
    margin-right: 0.5vw;
}

.registration_right_banner_title {
    font-size: 1.5vw;
    font-weight: 500;
    text-align: center;
}

.progress_bar_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5vw;
    flex-direction: column;
}

.progress_bar_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}


.first_bar_container {
    border: 0.2vw solid var(--primary-color);
    border-radius: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23%;
}

.second_bar_container {
    border: 0.2vw solid var(--primary-inactive-background-color);
    background-color: var(--primary-inactive-background-color);
    border-radius: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23%;
}

.middle_line_container {
    height: 0.1vw;
    width: 50%;
    background-color: var(--primary-inactive-background-color);

}

.middle_line_container_active {
    height: 0.1vw;
    width: 50%;
    background-color: var(--primary-color);

}

.first_bar_container .inactive_state_text_style {
    font-size: 1.6vw;
    font-weight: 500;
    color: var(--primary-color);
}

.second_bar_container h2 {
    font-size: 1.6vw;
    font-weight: 500;
    color: var(--primary-border-color);
}

.first_stage_completed_container {
    background-color: var(--primary-color);
    cursor: pointer;
}

.active_state_text_style {
    font-size: 1.6vw;
    font-weight: 500;
    color: var(--secondary-background-color);

}

.second_bar_container_active {
    border: 0.2vw solid var(--primary-color);
    border-radius: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23%;
}

.active_state_text_style_second {
    font-size: 1.6vw;
    font-weight: 500;
    color: var(--primary-color);
}

.text_below_progressbar_container {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
}

.basic_details_container {
    width: 35%;
    margin-left: 1.4vw;
    cursor: pointer;

}

.back-to-basic-details-button svg {
    width: 1vw;
    height: 1vw;
}

.back-to-basic-details-button p {
    font-size: 0.9vw;
    text-decoration: underline;
    cursor: pointer;
}

.basic_details_container p {
    width: 100%;
    font-size: 1vw;
}

.Verify_details_container p {
    font-size: 1vw;
}

.Verify_details_container {
    width: 35%;
}



.Verify_details_Inactive {
    color: var(--primary-border-color);
}

.name_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name_container div.input-field-outer-container {
    width: 49%;
}

.checkbox_conatiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.5vw 0 0 0;
}

.terms_condition-checkbox {
    width: 1.3vw;
    height: 1vw;
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: first baseline;
    margin-top: 0.2vw;
}



.button_contianer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5vw 0 0 0;

}

.button_style {
    width: 13vw;
}

.mobile_otp_container {
    margin: 0.6vw 0 0 0;
    // padding: 1vw;

}

.mobile_otp_style {
    width: 100%;
}

.otp_text {
    font-size: 0.9vw;
    font-weight: 400;
    margin-bottom: 0.5vw;
}

.resend_otp_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.8vw;
}

.time_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.time_text {
    font-size: 0.9vw;
    font-weight: 400;
}

.resend_container {

    width: 20%;


}

.resend_link {
    font-size: 0.9vw;
    font-weight: 400;
    color: #3257F180;
    text-align: end;
    cursor: pointer;

}

.horizontal_line_container {
    height: 0.11vw;
    border: 0.1vw solid black;
    margin-top: 0.8vw;
}

.registration-form-error-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0.1vw solid var(--primary-error-color);
    padding: 0 0.2vw;
    border-radius: 0.25vw;
    max-width: 100%;
    margin-top: 0.4vw;
}

.registration-form-error-container :first-child {
    margin-right: 0.25vw;
    color: var(--primary-error-color);
    margin-top: 0.15vw;
    font-size: 1vw;
}

.registration-form-error-container p {
    font-size: 0.9vw;
    color: var(--primary-error-color);
    flex: 1;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

.progress_bar_container+.text_input_container {
    margin-top: 2vw;
}

.progress_bar_container+.mobile_otp_container {
    margin-top: 2.5vw;
}

.registration-api-error-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1vw;
}

.email_otp_container {
    margin: 0.8vw 0 0 0;

}

.already_account_container p {
    text-align: center;
    margin-top: 0.8vw;
    font-size: 0.9vw;
    font-weight: 400;
}

.already_account_container p a,
.need_help_container p a {
    color: #3257F1;
    cursor: pointer;
}

.need_help_container p {
    text-align: center;
    margin-top: 0.4vw;
    font-size: 0.9vw;
    font-weight: 300;
}

.deactive-register-btn {
    background: #008AF180;
    border: none;
}

.Calendar_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.8vw 0 0 0;
}

.back-to-basic-details-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5vw;
    padding: 0 6vw;
    //margin-bottom: 1vw;
}

.back-to-basic-details-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-color);
    margin-bottom: 0.5vw;
}


/* Media query for mobile */
@media only screen and (max-width:$mobileMaxWidth) {
    
    .NewRegistrationForm_main_banner_container {
        width: 100%;
        background-color: var(--secondary-text-color);
        padding: 2vw;
        
    }
  
    .NewRegistrationForm_main_banner_container {
        padding: 0;
        margin: 0;
    
    }

    .NewRegistrationForm_main-banner-content-container {
        border-radius:5vw;
        border: none;
        
    }

    .registration_inner_left_banner_container {
        display: none;
    }

    .registration_inner_right_banner_container {
        width: 100%;
        // border: none;
        padding: 3.5vw;
       // margin-top: 1vw;
    }

    .name_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
    }
    

    .registration_right_banner_title {
        font-weight: 500;
        font-size: 4.5vw;
    }

    .first_bar_container {
        width: 10vw;
        height: 10vw;
        border-radius: 10vw;
        border-width: 1.2vw;
    
    }

    .inactive_state_text_style {
        font-size: 5vw !important;
    }

    .middle_line_container {
        height: 0.7vw;
    }

    .second_bar_container {
        width: 10vw;
        height: 10vw;
        border-radius: 10vw;
        border-width: 1.2vw;
    }

    .inactive_state_text_style_second {
        font-size: 5vw !important;

    }

    .text_below_progressbar_container {
        width: 100%;
    }

    .basic_details_container {
        width: 50%;
    
    }

    .basic_details_container p {
        text-align: center;
        font-size: 4vw;
        margin-left: 5vw;

    }

    .Verify_details_container {
        width: 50%;

    }

    .Verify_details_container p {
        margin-left: 3vw;
        font-size: 4vw;

    }

    .progress_bar_container + .text_input_container {
        margin-top: 13vw;
    
    }

    .name_container {
        flex-direction: column;
    }

    .input-field-outer-container {
        width: 100% !important;
    }

    .registration_inner_right_banner_container {
        border: 0.1vw solid var(--secondary-border-color);
        border-radius: 5vw;
        width: 100%;
        background-color: var(--secondary-background-color);
    
    }

    .button_style {
        margin-top: 2vw;
        width: 100%;;
    }

    .already_account_container p {
        //text-align: center;
        margin-top: 4vw;
        font-size: 3.6vw;
        font-weight: 400;
        
    }

    .need_help_container p {
        text-align: center;
        margin-top: 0.8vw;
        font-size: 3.5vw;
        font-weight: 300;
    }

    .active_state_text_style {
        font-size: 5vw;
        font-weight: 500;
        color: var(--secondary-background-color);
    
    }

    .middle_line_container_active {
        height: 0.7vw;
        width: 50%;
        background-color: var(--primary-color);
    
    }

    .second_bar_container_active {
        border: 1vw solid var(--primary-color);
        border-radius: 5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10vw;
        height:10vw;
    }

    .active_state_text_style_second {
        font-size: 5vw;
        font-weight: 500;
        color: var(--primary-color);
    }

    .progress_bar_container + .mobile_otp_container {
        margin-top: 7vw;
    }

    .otp_text {
        font-size: 3.5vw;
        font-weight: 400;
        margin-bottom: 1.5vw;
    }

    .time_text {
        font-size: 3vw;
        font-weight: 400;
    }

    .resend_link {
        font-size: 3vw;
        font-weight: 400;
        color: #3257F180;
        text-align: end;
    
    }

    .registration-form-error-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        border: 0.1vw solid var(--primary-error-color);
        padding: 0 1.3vw;
        border-radius: 1vw;
        width: 100%;
        margin-top: 0.5vw;
        
    }
    
    .registration-form-error-container :first-child {
        margin-right: 1vw;
        color: var(--primary-error-color);
        margin-top: 0.9vw;
        font-size: 3.8vw;
        
    }
    
    .registration-form-error-container p {
        font-size: 3.7vw;
        color: var(--primary-error-color);
        flex: 1;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }

    .registration-api-error-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 3vw;
    }

    .back-to-basic-details-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.5vw;
        //padding: 0 6vw;
        margin-bottom: 1.5vw;
    }
    
    .back-to-basic-details-button {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: var(--primary-color);
    }
}