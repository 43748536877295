@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.show-discount-main-container {
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 1vw;
    position: relative;
    padding: 1.5vw;
    margin-top: 2vw;
}

.total-fees-section {
    font-size: 1.1vw;
    font-weight: 600;
}

.discount_type_text_container {
    width: 30%;
}

.discount_type_text_container h3 {
    font-size: 1vw;
    font-weight: 500;
}

.discount_container .input-field-outer-container {
    margin-top: 0vw;
}

.Admission_form_calculate_discount_button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 1vw;
}

.Calculate_discount_button {
    width: 35%;
}

.discount_category_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}

.discount_container {
    width: 100%;
}

.discount_cross_button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.discount_cross_button svg {
    width: 1.5vw;
    height: 1.5vw;
}

.discount_cross_button div:hover {
    cursor: pointer;
}

.show-discount-main-container.is-modal {
    border: unset;
}

.discount_cross_button.is-modal {
    right: 1.5%;
    top: -5%;
    position: absolute;
    display: block;
}

@media only screen and (max-width:$mobileMaxWidth) {
    .show-discount-main-container {
        border: none;
        //border-radius: 1vw;
        position: relative;
        padding: 1.5vw;
        margin-top: 2vw;
    }

    .total-fees-section {
        font-size: 5vw;
        font-weight: 600;
        margin-top:1.2vw;
    }

    .discount_type_text_container {
        width: 100%;
    }

    .discount_type_text_container h3 {
        font-size: 4.5vw;
        font-weight: 500;
    }

    .discount_category_container {
        display: flex;
        align-items: start;
        flex-direction: column;
    }

    .discount_cross_button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

    }

    .discount_cross_button svg {
        width: 5vw;
        height: 5vw;
       // margin-top: 2vw;
    }

    .Calculate_discount_button {
        width: 100%;
    }
    .show-discount-main-container.is-modal {
        border: unset;
        padding: 2vw;
    }
    
    .discount_cross_button.is-modal {
        right: 1.5%;
        top: -5%;
        position: absolute;
        display: block;
        
    }
    
}