@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';


.ParentDetails_main_container {
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 1vw;
    position: relative;
    padding: 1.5vw;
    margin-top: 2vw;
}

.ParentDetails_title_container {
    background-color: var(--secondary-background-color);
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    padding: 0 0.3vw;
}

.ParentDetails_title_container h1 {
    font-size: 1.5vw;
    font-weight: 500;
}

.ParentDetails_content_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // flex-direction: column;
}

.ParentDetails_left_container {
    width: 50%;
    padding: 0 1.5vw 0 0;
}

.work_contianer,
.Residential_contianer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 1vw 0;
}

.work_text_container h3,
.Residential_text_container h3 {
    font-size: 1vw;
    font-weight: 500;
}



.ParentDetails_right_container {
    width: 50%;
    padding: 0 0 0 1.5vw;

}

.parent-city .input-field-outer-container {
    margin-bottom: 0.8vw;
}


.row_class {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.parents_first_name,
.parents_last_name,
.parents_mobile_number,
.parents_occupation,
.parents_email_id {
    width: 48%;
}

.parent_state_container {
    margin-bottom: 0.8vw;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .ParentDetails_main_container {
        border: none;
        //border-radius: 1vw;
        position: relative;
        padding: 2vw;
        // margin-top: 2vw;
        //display: none;
    }

    .ParentDetails_title_container {
        background-color: var(--secondary-background-color);
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, -50%);
        padding: 0 0.3vw;
        margin-top: 1vw;
    }

    .ParentDetails_title_container h1 {
        font-size: 4.5vw;
        font-weight: 500;
        margin-bottom: 2vw;
    }

    .ParentDetails_content_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }

    .ParentDetails_left_container {
        width: 100%;
        padding: 0 1.5vw 0 0;
    }

    .ParentDetails_right_container {
        width: 100%;
        padding: 0 1.5vw 0 0;
    }

    .row_class {
        // display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }

    .parents_first_name,
    .parents_last_name,
    .parents_mobile_number,
    .parents_occupation,
    .parents_email_id {
        width: 100%;
    }

    .parent_state_container {
        width: 100%;
        padding-left: 0;
        margin-bottom: 2.5vw;
    }
}