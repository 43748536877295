@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.Admission_form_section_container {
    padding: 0.5vw 6vw 2vw 6vw;
    margin: 0 auto;
    background-color: var(--secondary-background-color);
}

.Admission_form_main_container {
    border: 0.1vw solid var(--primary-text-color);
    border-radius: 1vw;
}

// form title section Starts

.form_title_parent_container {
    border-bottom: 0.1vw solid var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.apply-discount-button-admission-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.apply-discount-button-admission-form p {
    color: var(--primary-color);
    font-size: 1vw;
}

.apply-discount-button-admission-form div:hover {
    text-decoration: underline;
    cursor: pointer;
}

.logo_container {
    padding: 0.5vw;
    margin: 0.5vw;
}

.Institute_logo_img {
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    width: 18vw;
}

.title_details_container {
    margin-right: 10vw;
}

div.close-button {
    font-size: 1.1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-top: 0.3vw;
    color: var(--over-due-pie_chart-color);
    cursor: pointer;
}

.institute_details_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.institute_title_header h1 {
    font-size: 3vw;
    font-weight: 600;
}

.address_container {
    align-self: flex-start;
    text-align: start;
}

.address_container p {
    font-size: 1.3vw;
    font-weight: 300;
    text-align: center;
}

.contact_info_contianer p {
    margin-top: 0.5vw;
    font-size: 1.3vw;
    font-weight: 300;
    text-align: center;
}

.admission_form_container {
    align-self: center;
    text-align: center;
    margin-top: 1.5vw;
}

.admission_form_container h1 {
    font-size: 2.5vw;
    font-weight: 500;
}

.profile_img_container {
    position: relative;
}

.profile_img_wrapper {
    cursor: pointer;
    position: absolute;
    top: 0vw;
    height: 11vw;
    left: 0vw;
    z-index: 99;
    width: 9vw;
    justify-content: center;
    display: flex;
    align-items: center;
}

.profile_img_wrapper label {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.profile_img_wrapper h1 {
    font-size: 3vw;
    font-weight: 500;
}

.profile_img_wrapper h4 {
    font-size: 1vw;
}

.uploaded_profile_img_wrapper {
    // padding: 0.5vw;
    // margin: 0.5vw;
    height: 11vw;
    width: 9vw;
    overflow: hidden;
    border: 0.1vw solid var(--primary-text-color); //photo border here
}

.uploaded_profile_img {
    max-width: 100%;
    // height: auto;
    margin: 0;
    padding: 0;
    // width: 10vw;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

// form title section ends

// admission details section starts
.Admission_details_parent_container {
    padding: 3vw 3vw 1.5vw 3vw;
}

.Admission_form_common_button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Optional: adjust height if needed */
}

.Admission_form_common_submit_button {
    width: 35%;
}

.admission-form-error-container+.Admission_form_common_button {
    margin-top: 0.3vw;
}

.Admission_form_common_button {
    margin-top: 2.25vw;
}

.modal-overlaycontent_studentfees.warning-modal-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content_studentfees.warning-modal-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw 0;
    flex-direction: column;
}

.warning-modal-for-image p {
    font-weight: 500;
    font-size: 1.1vw;
    padding: 0 1.5vw
}

.warning-modal-button-container {
    margin-top: 1vw;
}

// CircularProgress.scss
.circular-progress {
    position: absolute;
    top: 3.4vw;
    left: 2.65vw;
    z-index: 99;
    width: 4vw;
    height: 4vw;

    svg {
        transform: rotate(-180deg); // Rotate SVG by 180 degrees to start from the top

        circle {
            transition: stroke-dashoffset 0.35s;
            transform: rotate(90deg); // Rotate the circle to align with the top
            transform-origin: center;
        }
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1vw;
        font-weight: 600;
    }
}



// admission details section ends

@media only screen and (max-width:$mobileMaxWidth) {

    .Admission_form_section_container {
        padding: 4vw;
        margin: 0 auto;
        width: 95%;
    }

    .Admission_details_parent_container {
        padding: 4vw 3vw 1.5vw 3vw;
    }

    .Admission_form_main_container {
        border: 0.2vw solid var(--primary-text-color);
        border-radius: 4vw;
    }

    .form_title_parent_container {
        border-bottom: 0.2vw solid var(--primary-text-color);
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        margin-bottom: 1.5vw;
    }

    .logo_container {
        padding: 0.5vw;
        margin: 0.5vw;
    }

    .Institute_logo_img {
        max-width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        width: 55vw;
    }

    .title_details_container {
        margin-right: 1vw;
    }

    .institute_details_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-left: 4vw;
    }

    .institute_title_header h1 {
        font-size: 7vw;
        font-weight: 600;
    }

    .address_container {
        align-self: flex-start;
        text-align: center;
    }

    .address_container p {
        font-size: 4vw;
        font-weight: 300;
        text-align: center;
        margin-left: 0.5vw;
    }

    .contact_info_contianer p {
        margin-top: 0.5vw;
        font-size: 2.5vw;
        font-weight: 300;
        text-align: center;
    }

    .profile_img_wrapper {
        padding: 4.5vw 3vw;
        cursor: pointer;
        display: none;
    }

    .admission_form_container {
        align-self: center;
        text-align: center;
        margin-top: 2.3vw;
    }

    .admission_form_container h1 {
        font-size: 5vw;
        font-weight: 500;
    }

    .Admission_form_common_submit_button {
        width: 100%;
        //  display: none;
    }

    .Admission_form_navigation_button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .Admission_form_next_button {
        justify-content: flex-end;
    }

    .Admission_form_back_button {
        justify-content: flex-start;
    }

    .apply-discount-button-admission-form p {
        display: none;
    }



    .apply-discount-button p {
        color: var(--primary-color);
        font-size: 3vw;
        margin-left: 55vw;
    }

    .apply-discount-button div:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .profile_img_container {
        position: relative;
        margin-bottom: 2vw;
        margin-top: 1vw;
    }

    .profile_img_wrapper {
        cursor: pointer;
        position: absolute;
        top: 5vw;
        height: 11vw;
        left: 7vw;
        z-index: 99;
        width: 9vw;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .profile_img_wrapper label {
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .profile_img_wrapper h1 {
        font-size: 3vw;
        font-weight: 500;
    }

    .profile_img_wrapper h4 {
        font-size: 4vw;
    }

    .uploaded_profile_img_wrapper {
        // padding: 0.5vw;
        // margin: 0.5vw;
        height: 25vw;
        width: 23vw;
        overflow: hidden;
        border: 0.2vw solid var(--primary-text-color); //photo border here
    }

    .uploaded_profile_img {
        max-width: 100%;
        // height: auto;
        margin: 0;
        padding: 0;
        // width: 10vw;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    div.close-button {
        font-size: 3.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        margin-top: 0.3vw;
        color: var(--over-due-pie_chart-color);
        cursor: pointer;
    }

    .modal-content_studentfees.warning-modal-inner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1vw 0;
        flex-direction: column;
    }

    .warning-modal-for-image p {
        font-weight: 500;
        font-size: 4vw;
        padding: 0 2.5vw
    }

    .warning-modal-button-container {
        margin-top: 1vw;
        width: 15vw;
    }




}