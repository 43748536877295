@import '../../Utils//Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.Dashboard_Section_conatainer {
    padding: 2vw 6vw;
    margin: 0 auto;
}

.Pie_Chart_Parent_Container {
    border: 0.1vw solid var(--primary-text-color);
    display: flex;
    width: 100%;
    border-radius: 0.3vw;

}

.pie_Chart_Left_Container {
    padding: 1vw 0 3vw 2vw;
    width: 50%;
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
}

.pie_Chart_Right_Container {
    width: 50%;
    border-left: 0.1vw solid var(--primary-text-color);
}


.Total_Students_title {
    border-bottom: 0.1vw solid var(--primary-text-color);
    width: 29%;
    font-size: 1.2vw;
    font-weight: 500;
    line-height: 1.2vw;
}

.Total_students_number {
    margin-top: 0.7vw;
    font-size: 1.2vw;
    font-weight: 500;
}

.Pass_Out_Students_container {
    margin-top: 2vw;
}

.Pass_Out_Students_title,
.Pass_Out_students_number {
    font-size: 1.2vw;
    font-weight: 500;
}

.Fees_Collection_Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5vw 2vw 0 2vw;
}

.Fees_collection_title {
    width: 50%;
    text-align: center;
    font-size: 1.2vw;
    font-weight: 500;
}

.Intervals_Container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.radio_button_container {
    display: flex;
    align-items: center;
    margin-right: 1vw;
}

.Pie_Chart_Contianer {
    margin: 0.5vw 0 0.5vw 0;
}

.css-66gjpw-MuiResponsiveChart-container {
    margin: 0 3vw;
}

// Filter Section Starts

.filter_section_parent_container {
    display: flex;
    align-content: center;
    margin: 1vw 0;
    justify-content: space-between;
}

.Reset_button_style {
    padding: 0.1vw 0.95vw;
}

// Filter Section ends

// Search Section Starts

.Search_section_parent_container {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.Search_main_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.search_bar_container {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border: 0.1vw solid var(--primary-text-color);
    padding: 0.5vw 0.5vw;
    width: 72%;
    border-radius: 0.3vw;
    // transition: transform 0.2s, box-shadow 0.2s;
    // background: rgba(255, 255, 255, 0.1); // Translucent background
    // border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    // box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    // backdrop-filter: blur(1vw); // Frosted glass blur effect
    // -webkit-backdrop-filter: blur(1vw);
}

.search_icon {
    padding: 0 1.3vw;
    display: flex;
    align-items: center;
    color: var(--primary-border-color);
}

.search_input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 1.3vw;
    font-weight: 500;
    background: transparent; // Translucent background

}

.search_input::placeholder {
    color: var(--primary-text-color);
    font-size: 1.3vw;
    font-weight: 500;
}

.add_Student_Contianer {
    width: 27%;
}

.add_Student_Mobile_Contianer {
    display: none;
}

.Add_Student_button_style {
    padding: 0.31vw 0.95vw;

}

.Dashboard_main_container .common-dropdown-container select {
    width: 12vw;
}

// Search Section ends

.recently-added-students-container p {
    font-size: 1.1vw;
    font-weight: 600;
}

.dashboard-student-list-loader {
    width: 100%;
    height: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-student-list-loader div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-student-list-loader .spinner {
    font-size: 2.4vw;
    margin-left: unset;
}

.dashboard-student-list-loader p {
    font-size: 1vw;
    margin-top: 0.4vw;
}

.dashboard_button_container {
    display: flex;
    //justify-content: space-between;
}

.reset_button_container {
    // display: flex;
    // justify-content: space-between;
    margin-right: 0.5vw;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .filter_section_parent_container {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin: 1vw 0;
        //justify-content: space-between;
    }

    .Dashboard_main_container .common-dropdown-container select {
        width: 100% !important;
        margin-bottom: 2vw;
    }

    .dashboard_button_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reset_button_container {
        width: 45%;
    }

    .apply_button_container {
        width: 45%;
    }

    .add_Student_Contianer {
        display: none;
    }

    .add_Student_Mobile_Contianer {
        display: block;
        //align-items: center;
        width: 100%;
    }

    .Search_section_parent_container {
        display: flex;
        align-content: center;
        justify-content: flex-start;
    }


    .Search_main_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .search_bar_container {
        display: flex;
        align-content: center;
        justify-content: space-between;
        border: 0.2vw solid var(--primary-text-color);
        padding: 0.5vw 0.5vw;
        width: 100%;
        border-radius: 1.5vw;
        // transition: transform 0.2s, box-shadow 0.2s;
        // background: rgba(255, 255, 255, 0.1); // Translucent background
        // border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        // box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        // backdrop-filter: blur(1vw); // Frosted glass blur effect
        // -webkit-backdrop-filter: blur(1vw);
    }

    .search_icon {
        padding: 0 2vw;
        display: flex;
        align-items: center;
        color: var(--primary-border-color);
    }

    .search_icon svg {
        font-size: 5vw;
    }

    .search_input {
        border: none;
        outline: none;
        flex: 1;
        font-size: 8vw;
        width: 90%;
        font-weight: 500;
        margin-bottom: 0.5vw;
    }

    .search_input::placeholder {
        color: var(--primary-text-color);
        font-size: 6vw;
        font-weight: 400;
        // margin-bottom: 1vw;
    }

    .dashboard-student-list-loader div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dashboard-student-list-loader {
        width: 100%;
        height: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dashboard-student-list-loader .spinner {
        font-size: 8.4vw;
        margin-left: unset;
    }

    .dashboard-student-list-loader p {
        font-size: 4vw;
        margin-top: 0.4vw;
    }

    .recently-added-students-container p {
        font-size: 4vw;
        font-weight: 600;
    }


}