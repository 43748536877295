@import "../../../Utils/Styles/Colors.css";
@import "../../../Utils/Styles/StylesConfig.scss";

.profile_main_container {
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
}

.profile-container{
    max-height: 22vw;
    display: flex;
    justify-content: space-between;
   // align-items: flex-start;
    flex-direction: column;
}

.close-button-main-container {
    display: flex;
    justify-content: end;
}

.profile-close-button-icon {
    display: flex;
    justify-content: flex-end;
}

.profile-close-button-icon svg {
    cursor: pointer;
}

.profile-icon-main-container {
    display: flex;
    justify-content: center;
}

.profile-icon-main-container svg {
    width: 6vw;
    height: 6vw;
}

.profile-user-detail-container {
    display: flex;
    flex-direction: column;
    //margin-top: 1vw;
    border-bottom: 0.2vw solid black;
}

.profile-name-container {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 1.1vw;
}

.profile-email-container {
    display: flex;
    justify-content: center;
    font-size: 1.1vw;
}

.profile-edit-icon {
    display: flex;
    flex-direction: row;
    margin-top: 0.8vw;
    cursor: not-allowed;
}

.profile-edit-icon svg {
    width: 1.5vw;
    height: 1.5vw;
    margin-left: 0.2vw;
}

.profile-edit-icon p {
    font-size: 1vw;
    margin-left: 1vw;
}

.profile-subscription-icon {
    display: flex;
    flex-direction: row;
    cursor: not-allowed;
   // margin-top: 1vw;
}

.profile-subscription-icon svg {
    width: 1.5vw;
    height: 1.5vw;
    margin-left: 0.3vw;
}

.profile-subscription-icon p {
    font-size: 1vw;
    margin-left: 1vw;
}

.profile-change-password-icon {
    display: flex;
    flex-direction: row;
    cursor: not-allowed;
   // margin-top: 1vw;
}

.profile-change-password-icon svg {
    width: 1.2vw;
    height: 1.2vw;
    margin-left: 0.3vw;
}

.profile-change-password-icon p{
    font-size: 1vw;
    margin-left: 1vw;
}

.profile-logout-container{
    display: flex;
    justify-content: center;
   margin-top: 1.6vw;
   margin-bottom: 1vw;
}

.profile-popup-bottom-button-help {
    margin-right: 0.4vw;
    cursor: not-allowed;
    font-size: 1.1vw;
}

.profile-popup-bottom-button-logout {
    margin-left: 0.4vw;
    font-size: 1.1vw;
}