:root {
    /* --primary-color: #008af1; */
    --primary-color: #155EEF;
    --secondary-color: #31e55e;
    --primary-text-color: #2d3142;
    --secondary-text-color: #ffffff;
    --primary-border-color: #787878;
    --secondary-border-color: #5f5f5f;
    --primary-error-color: #df3238;
    --primary-background-color: #f7f7f7;
    --secondary-background-color: #ffffff;
    --primary-inactive-background-color: #c9c9c9;
    --primary-inactive-text-color: #787878;
    --primary-text-input-field-border-color: #3f3f3f;
    --primary-placeholder-color: #808080;
    --main-banner-primary-color: #91f288;
    --main-banner-secondary-color: #00c986;
    --modal-background-color: rgba(0, 0, 0, 0.5);
    --paid_pie_chart-color: #00FF40;
    --pending-pie_chart-color: #FFFD00;
    --over-due-pie_chart-color: #FF0000;
    --table-even-row-background-color: #EFEDED
}