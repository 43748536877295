@import './Utils/Styles/Fonts.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* user-select: none; */
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
li,
dt,
dd,
figure,
figcaption,
blockquote,
pre {
  margin: 0;
  padding: 0;
}

body {
  font-family: Poppins;
  line-height: 1.6;

}

body::-webkit-scrollbar {
  display: none;
}


nav ul li,
li {
  list-style: none;
}


nav ul li a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-hide {
  overflow: hidden;
}