@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.notification_main_container{
    padding:0.5vw 0.5vw 0.5vw 0.5vw;
}

.notification_header_main_container{
    display: flex;
    flex-direction: row;
    border-bottom: 0.2vw solid black;
    //justify-content: center;
}

.notification_header_container{ 
    display: flex;
    font-size: 2vw;
    font-weight: 500;
    //justify-content: center;
    margin-left: 5.5vw;
}

.envelop_icon{
    display: flex;
    //justify-content: end;
    margin-left: 4vw;
    margin-top: 0.5vw;
}

.envelop_icon svg{
    width: 2vw;
    height: 2vw;
}

.notification_close_button{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width:$mobileMaxWidth){

    .notification_main_container{
        padding:2vw 2vw 2vw 2vw;
    }

    .notification_header_main_container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-bottom: 0.5vw solid black;
    }

    .notification_header_container{ 
        display: flex;
        align-items: center;
        //justify-content: center;
        //flex-direction: row;
        //border-bottom:none;
        font-size: 6vw;
        font-weight: 500;
        margin-left: 25vw;
    }

    .envelop_icon{
        margin-left: 17vw;
        margin-top: 2vw;
    }
    
    .envelop_icon svg{
        width:6vw;
        height: 6vw;
    }
    
    .notification_close_button{
        display: flex;
        justify-content: flex-end;
    }

    .notification_close_button svg{
        width: 5vw;
        height: 5vw;
    }

    .notification_message_main_container{
        display: flex;
       // border-bottom: 0.2vw solid black;
    }

    .message_container{
        display: flex;
        flex-direction: column;
        width: 100vw;
        //border-bottom: 0.2vw solid black;
    }
    
}