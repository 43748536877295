@import '../../../Utils//Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.hero-section-outer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.hero-section-main-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    flex-direction: row;
    background: linear-gradient(to right, var(--main-banner-primary-color), var(--main-banner-secondary-color));
    padding: 3.3vw 4.5vw;
}

.hero-left-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.hero-right-section {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.hero-carousel-image {
    width: 41.7vw;
    height: auto;
}

.hero-left-content-section {
    margin-top: 2vw;
}

.hero-left-content-section .hero-left-section-heading {
    font-size: 2.1vw;
    font-weight: 600;
}

.hero-left-content-section .hero-left-section-para {
    font-size: 1.25vw;
    font-weight: 400;
    margin-top: 1vw;
    margin-right: 5.6vw;
}

.hero-section-dots-container {
    width: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1vw;
}

.hero-section-dots-container .hero-caraousel-dot {
    width: 0.75vw;
    height: 0.75vw;
    border-radius: 1vw;
    background-color: var(--primary-inactive-background-color);
}

.hero-section-dots-container .hero-caraousel-dot.active {
    background-color: var(--secondary-color);
}

@media only screen and (max-width: $mobileMaxWidth) {
    .hero-section-main-container {
        position: relative;
        // z-index: -9;
    }

    .hero-left-section {
        width: 80%;
    }

    .hero-left-content-section {
        margin-top: 2.5vw;
    }

    .hero-left-content-section .hero-left-section-heading {
        font-size: 5vw;
    }

    .hero-left-content-section .hero-left-section-para {
        font-size: 3vw;
        margin-right: 17vw;
        margin-top: 2.5vw;
    }

    .hero-left-button-container {
        width: 40vw;
        margin-top: 8vw;
    }

    .hero-right-section {
        width: unset;
        position: absolute;
        bottom: 3vw;
        right: 3vw;
    }

    .hero-carousel-image {
        width: 45vw;
    }

    .hero-section-dots-container {
        width: 12%;
        margin-top: 2.5vw;
    }

    .hero-section-dots-container .hero-caraousel-dot {
        width: 2.5vw;
        height: 2.5vw;
        border-radius: 2.5vw;
    }
}