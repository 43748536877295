@import '../../Utils//Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.back-to-dashboard-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5vw 0;
    // padding: 0 6vw;
}

.back-to-dashboard-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--primary-color);
}

.back-to-dashboard-button :first-child {
    font-size: 0.8vw;
    margin-right: 0.25vw;
}

.back-to-dashboard-button p {
    text-decoration: underline;
    font-size: 0.8vw;
}

.back-to-dashboard-button:hover {
    cursor: pointer;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .back-to-dashboard-button p {
        text-decoration: underline;
        font-size: 3.8vw;
    }

    .back-to-dashboard-button :first-child {
        font-size: 3.8vw;
        margin-right: 0.5vw;
    }
}