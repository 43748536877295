@import '../../../Utils/Styles/Colors.css';
@import '../../../Utils/Styles/StylesConfig.scss';

.common-dropdown-container {
    position: relative;
}


.common-dropdown-container .input-error-container {
    right: 1.5vw;
    top: 0.65vw;
}

.common-dropdown-container .input-error-text-container {
    position: absolute;
    top: -1.5vw;
    right: -1.15vw;
    background-color: var(--secondary-background-color);
    border: 0.1vw solid var(--primary-error-color);
    padding: 0vw 0.15vw;
    border-radius: 0.25vw;
    max-width: 100%;
    z-index: 9999;
}

.common-dropdown-container .input-error-text-container::after {
    content: '';
    position: absolute;
    top: 1.6vw;
    right: 2.65vw;
    width: 0;
    height: 0;
    border-left: 0.6vw solid transparent;
    border-right: 0.6vw solid transparent;
    border-top: 0.6vw solid var(--primary-error-color);
}

.common-dropdown-container .input-error-text-container p {
    font-size: 1vw;
    font-weight: 300;
    color: var(--primary-error-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.dropdown-spinner {
    animation: dropdownloaderspin 1s linear infinite;
    font-size: 1.4vw;
    margin-left: 0.4vw;
    position: absolute;
    top: 0.55vw;
    right: 1.5vw;
}

@keyframes dropdownloaderspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.common-dropdown-container .default-class {
    padding: 0.5vw;
    font-size: 1.2vw;
    border-radius: 0.3vw;
    border: 0.1vw solid var(--primary-text-color);
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color);
    width: 100%;
    outline: none;
    justify-content: space-between; // Webkit support for older browsers
}


.common-dropdown-container .custom-styled-select {
    padding: 0.5vw;
    font-size: 1.2vw;
    border-radius: 0.3vw;
    border: 0.1vw solid var(--primary-text-color);
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color);
    width: 100%;
    outline: none;
    justify-content: space-between;
    border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    background: rgba(255, 255, 255, 0.1); // Translucent background
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
}




@media only screen and (max-width:$mobileMaxWidth) {

    .common-dropdown-container .default-class {
        padding: 3vw;
        font-size: 4.5vw;
        border-radius: 1vw;
        border: 0.2vw solid var(--primary-text-color);
        // background-color: var(--secondary-background-color);
        color: var(--primary-text-color);
        width: 100%;
        outline: none;
        border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        background: rgba(255, 255, 255, 0.1); // Translucent background
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
        //margin-bottom: vw;
        justify-content: space-between;
    }

    .common-dropdown-container .custom-styled-select {
        padding: 3vw;
        font-size: 4.5vw;
        border-radius: 1vw;
        border: 0.2vw solid var(--primary-text-color);
        background-color: var(--secondary-background-color);
        color: var(--primary-text-color);
        width: 100%;
        outline: none;
        justify-content: space-between;
        border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        background: rgba(255, 255, 255, 0.1); // Translucent background
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw); // Webkit support for older browsers
    }

    .common-dropdown-container .input-error-container {
        right: 3.5vw;
        top: 3.5vw;
    }

    .common-dropdown-container .input-error-text-container {
        position: absolute;
        top: -2.9vw;
        right: -3.2vw;
        background-color: var(--secondary-background-color);
        border: 0.1vw solid var(--primary-error-color);
        padding: 0vw 0.15vw;
        border-radius: 0.5vw;
        max-width: 100%;
        z-index: 9999;
    }

    .common-dropdown-container .input-error-text-container::after {
        content: '';
        position: absolute;
        top: 5vw;
        right: 7.7vw;
        width: 0;
        height: 0;
        border-left: 1.2vw solid transparent;
        border-right: 1.2vw solid transparent;
        border-top: 1.2vw solid var(--primary-error-color);
    }

    .common-dropdown-container .input-error-text-container p {
        font-size: 3vw;
        font-weight: 400;
        color: var(--primary-error-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .dropdown-spinner {
        animation: dropdownloaderspin 1s linear infinite;
        font-size: 4.5vw;
        margin-left: 0.4vw;
        position: absolute;
        top: 2.8vw;
        right: 3.8vw;
    }



}