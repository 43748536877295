@import '../../Utils/Styles/Colors.css';
@import '../../Utils/Styles/StylesConfig.scss';

.student-attendance-record-parent-container {
    width: 100vw;
    margin: 0 0 5vw 0;
    padding: 0;
    position: relative;

}

// background shapes style start

.student-attendance-record-background-shapes-container {
    z-index: -1;
}

.student-attendance-record-blue_hexagon {
    position: absolute;
    top: -4vw;
    z-index: -2;
    width: 15vw;
    margin: 0 auto;
}



.student-attendance-record-green_hexagon {
    position: absolute;
    top: 11vw;
    z-index: -2;
    width: 8.5vw;
    margin: 0 auto;
}

.student-attendance-record-blue_gear {
    position: absolute;
    bottom: -5vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.student-attendance-record-green_gear {
    position: absolute;
    bottom: -5vw;
    left: 7.9vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.student-attendance-record-green_rectangle {
    position: absolute;
    bottom: -5vw;
    right: 0vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.student-attendance-record-blue_square {
    position: absolute;
    bottom: -5vw;
    right: 6.8vw;
    z-index: -2;
    width: 10vw;
    margin: 0 auto;
}

.student-attendance-record-green_circle {
    position: absolute;
    top: 2vw;
    right: 0;
    z-index: -2;
    width: 8.4vw;
    margin: 0 auto;
}

.student-attendance-record-blue_circle {
    position: absolute;
    top: 13vw;
    right: 0;
    z-index: -2;
    width: 4vw;
    margin: 0 auto;
}

// background shapes style end

.student-attendance-record-subscription-content-parent {
    margin: 0 auto;
    margin-top: 2vw;
    padding: 2vw 4vw;
    max-width: 83%;
    border-radius: 1vw;
    z-index: 3;
    transition: transform 0.2s, box-shadow 0.2s;
    background: rgba(255, 255, 255, 0.1); // Translucent background
    border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    backdrop-filter: blur(1vw); // Frosted glass blur effect
    -webkit-backdrop-filter: blur(1vw);
    display: flex;

}


.student-attendance-record-subscription-content-parent-secondary {
    // margin: 0 auto;
    margin-top: 2vw;
    padding: 2vw 4vw;
    max-width: 83%;
    border-radius: 1vw;
    z-index: 3;
    border: 0.1vw solid black;
    // transition: transform 0.2s, box-shadow 0.2s;
    // background: rgba(255, 255, 255, 0.1); // Translucent background
    // border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
    // box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
    // backdrop-filter: blur(1vw); // Frosted glass blur effect
    // -webkit-backdrop-filter: blur(1vw);
    display: flex;

}

.student-attendance-record-right-container,
.student-attendance-record-left-container {
    width: 50%;
}


.student-attendance-record-left-container-personal-details-name-container,
.student-attendance-record-left-container-personal-details-mobile-container,
.student-attendance-record-left-container-personal-details-std-container,
.student-attendance-record-left-container-personal-details-div-container,
.student-attendance-record-left-container-personal-details-roll-no-container,
.student-attendance-record-date-container {
    display: flex;
    align-items: center;
    margin-top: 0.5vw;

    h3 {
        font-size: 1.3vw;
        font-weight: 500;
    }

    p {
        font-size: 1.3vw;
        margin-left: 0.5vw;
    }

}

.student-attendance-record-left-container-personal-details-std-div-container {
    display: flex;
    align-items: center;
}

.student-attendance-record-left-container-personal-details-div-container {
    margin-left: 9vw;
}

.student-attendance-record-calendar {

    .react-calendar {

        transition: transform 0.2s, box-shadow 0.2s;
        background: rgba(255, 255, 255, 0.1); // Translucent background
        border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw);
        margin-top: 1vw;
        border-radius: 0.3vw;


        width: 70%; // Adjusts the overall width, can set it to a fixed width like '400px' or keep it responsive
        // max-width: 600px; // Sets a maximum width for larger screens
        font-size: 1.3vw; // Increases font size for date numbers

        // .react-calendar__tile {
        //     padding: 15px 0; // Increase padding of date tiles for larger click area
        // }

        .react-calendar__navigation button {
            font-size: 1.3vw; // Increase font size of navigation arrows and month/year label
        }

        .react-calendar__month-view__days__day {
            // height: 50px; // Increase the height of individual date cells
        }

        .react-calendar__month-view__weekdays {
            font-size: 1.3vw; // Increase font size of weekday labels (Sun, Mon, etc.)
        }

        .react-calendar__tile--active {
            background-color: var(--primary-color); // Customize background color for the active date
            color: var(--secondary-background-color);

        }

        // .react-calendar__tile--active {
        //     color: var(--primary-color);
        //     border: 2px dotted var(--primary-color);
        //     border-radius: 8px;
        //     padding: 4px;
        // }

        // .react-calendar__tile--active {
        //     position: relative;
        //     background-color: var(--primary-color-light);
        //     color: var(--primary-color);
        //     border-radius: 50%;
        //     overflow: hidden;
        // }

        // .react-calendar__tile--active::after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     width: 100%;
        //     height: 100%;
        //     background-color: var(--primary-color);
        //     border-radius: 50%;
        //     transform: translate(-50%, -50%) scale(0);
        //     opacity: 0.5;
        //     animation: ripple 2s forwards;
        //     /* forwards to retain the final state after the animation */
        // }

        // @keyframes ripple {
        //     0% {
        //         transform: translate(-50%, -50%) scale(0);
        //         opacity: 0.5;
        //     }

        //     100% {
        //         transform: translate(-50%, -50%) scale(2);
        //         opacity: 0;
        //     }
        // }

        // .react-calendar__tile--active::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     border: 2px dotted var(--primary-color);
        //     border-radius: 50%;
        //     opacity: 0;
        //     animation: borderAppear 2s forwards;
        //     /* Start after the ripple finishes */
        // }

        // @keyframes borderAppear {
        //     0% {
        //         opacity: 0;
        //     }

        //     100% {
        //         opacity: 1;
        //     }
        // }


    }


    .react-calendar__navigation {
        margin-bottom: 0;
    }

    .react-calendar__month-view__weekdays abbr[title] {
        text-decoration: none !important;

    }

    .highlight-present {
        background-color: var(--secondary-color) !important;
        color: var(--secondary-background-color);

    }

    .highlight-absent {
        background-color: var(--over-due-pie_chart-color) !important;
        color: var(--secondary-background-color);
    }


    .highlight-NA {
        background-color: var(--primary-border-color) !important;
        color: var(--secondary-background-color);
    }


    .highlight-comment {
        position: relative;
        border: 0.2vw solid var(--secondary-background-color);
    }

    .highlight-comment::after {
        content: '';
        position: absolute;
        bottom: 0.2vw;
        left: 50%;
        transform: translateX(-50%);
        width: 0.5vw;
        height: 0.5vw;
        background-color: var(--primary-color);
        border-radius: 50%;
    }
}

.calendar-legends {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;

    .legend-item {
        display: flex;
        align-items: center;
        margin-right: 20px;

        h3 {
            margin-left: 10px;
            font-size: 1.3vw;
            font-weight: 500;
        }

        .legend-box {
            width: 20px;
            height: 20px;
            border-radius: 4px;
        }
    }

    .present-container .present-box {
        background-color: var(--secondary-color); // Green for present
    }

    .absent-container .absent-box {
        background-color: var(--over-due-pie_chart-color); // Red for absent
    }

    .present-container .active-box {
        background-color: var(--primary-color);
    }

    .NA-box {
        background-color: var(--primary-border-color);
    }

    .absent-container .current-date-box {
        background-color: var(--pending-pie_chart-color); // Red for absent
    }
}

.student-attendance-record-submit-button-container {
    display: flex;
    justify-content: end;
    margin-top: 1vw;

    .student-attendance-record-submit-button {
        width: 5vw;
    }
}



.student-attendance-record-back-button-container {
    display: flex;
    // justify-content: end;
    margin-top: 1vw;

    .student-attendance-record-edit-button {
        width: 5vw;
    }
}



.student-attendance-record-right-container {

    .student-attendance-record-edit-button-container {
        display: flex;
        justify-content: end;
    }

    .student-attendance-record-edit-button {
        width: 5vw;
    }

    .student-attendance-record-date-status-comment-container {

        .after_submit_para {
            font-size: 1.3vw;
            margin-top: 0.5vw;
        }

        .student-attendance-record-status-container {
            display: flex;
            width: 100%;
            margin-top: 0.5vw;

            h4 {
                font-weight: 500;
            }

            .status-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    width: auto;
                    margin-left: 2vw;
                }
            }
        }

        .student-attendance-record-comment-container {
            margin-top: 1vw;
        }

    }

    table {
        width: 100%;
        border-collapse: collapse;
        background-color: var(--secondary-background-color);
        margin-top: 1.5vw;
    }

    th,
    td {
        border: 0.15vw solid var(--primary-text-color);
        padding: 0.4vw;
        text-align: center;
        font-size: 1.2;
    }


    th {
        background-color: var(--secondary-background-color);
    }

}

.student-attendance-record-back-button-container-mobile {
    display: none;
}

@media only screen and (max-width:$mobileMaxWidth) {

    .student-attendance-record-right-container,
    .student-attendance-record-left-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .student-attendance-record-left-container-personal-details-name-container,
    .student-attendance-record-left-container-personal-details-mobile-container,
    .student-attendance-record-left-container-personal-details-std-container,
    .student-attendance-record-left-container-personal-details-div-container,
    .student-attendance-record-left-container-personal-details-roll-no-container,
    .student-attendance-record-date-container {
        display: flex;
        align-items: center;
        margin-top: 1vw;

        h3 {
            font-size: 4.5vw;
            font-weight: 500;
        }

        p {
            font-size: 4.5vw;
            margin-left: 0.5vw;
        }

    }

    .student-attendance-record-left-container-personal-details-std-div-container {
        display: flex;
        align-items: center;
    }

    .student-attendance-record-left-container-personal-details-div-container {
        margin-left: 9vw;
    }

    .student-attendance-record-subscription-content-parent {
        margin: 0 auto;
        margin-top: 2vw;
        padding: 2vw 4vw;
        max-width: 83%;
        border-radius: 1vw;
        z-index: 3;
        transition: transform 0.2s, box-shadow 0.2s;
        background: rgba(255, 255, 255, 0.1); // Translucent background
        border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        backdrop-filter: blur(1vw); // Frosted glass blur effect
        -webkit-backdrop-filter: blur(1vw);
        display: flex;
        flex-direction: column;

    }


    .student-attendance-record-subscription-content-parent-secondary {
        // margin: 0 auto;
        margin-top: 2vw;
        padding: 2vw 4vw;
        max-width: 83%;
        border-radius: 1vw;
        z-index: 3;
        border: none;
        // transition: transform 0.2s, box-shadow 0.2s;
        // background: rgba(255, 255, 255, 0.1); // Translucent background
        // border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
        // box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
        // backdrop-filter: blur(1vw); // Frosted glass blur effect
        // -webkit-backdrop-filter: blur(1vw);
        display: flex;
        flex-direction: column;

    }

    .student-attendance-record-calendar {

        .react-calendar {

            transition: transform 0.2s, box-shadow 0.2s;
            background: rgba(255, 255, 255, 0.1); // Translucent background
            border: 0.1vw solid rgba(255, 255, 255, 0.2); // Subtle border for realism
            box-shadow: 0 0.2vw 3vw rgba(0, 0, 0, 0.1); // Soft shadow
            backdrop-filter: blur(1vw); // Frosted glass blur effect
            -webkit-backdrop-filter: blur(1vw);
            margin-top: 1vw;
            border-radius: 0.3vw;


            width: 100%; // Adjusts the overall width, can set it to a fixed width like '400px' or keep it responsive
            // max-width: 600px; // Sets a maximum width for larger screens
            font-size: 4.5vw; // Increases font size for date numbers

            // .react-calendar__tile {
            //     padding: 15px 0; // Increase padding of date tiles for larger click area
            // }

            .react-calendar__navigation button {
                font-size: 3.8vw; // Increase font size of navigation arrows and month/year label
            }

            .react-calendar__month-view__days__day {
                // height: 50px; // Increase the height of individual date cells
            }

            .react-calendar__month-view__weekdays {
                font-size: 3.5vw; // Increase font size of weekday labels (Sun, Mon, etc.)
            }

            .react-calendar__tile--active {
                background-color: var(--primary-color); // Customize background color for the active date
                color: var(--secondary-background-color);

            }

            // .react-calendar__tile--active {
            //     color: var(--primary-color);
            //     border: 2px dotted var(--primary-color);
            //     border-radius: 8px;
            //     padding: 4px;
            // }

            // .react-calendar__tile--active {
            //     position: relative;
            //     background-color: var(--primary-color-light);
            //     color: var(--primary-color);
            //     border-radius: 50%;
            //     overflow: hidden;
            // }

            // .react-calendar__tile--active::after {
            //     content: '';
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     width: 100%;
            //     height: 100%;
            //     background-color: var(--primary-color);
            //     border-radius: 50%;
            //     transform: translate(-50%, -50%) scale(0);
            //     opacity: 0.5;
            //     animation: ripple 2s forwards;
            //     /* forwards to retain the final state after the animation */
            // }

            // @keyframes ripple {
            //     0% {
            //         transform: translate(-50%, -50%) scale(0);
            //         opacity: 0.5;
            //     }

            //     100% {
            //         transform: translate(-50%, -50%) scale(2);
            //         opacity: 0;
            //     }
            // }

            // .react-calendar__tile--active::before {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     border: 2px dotted var(--primary-color);
            //     border-radius: 50%;
            //     opacity: 0;
            //     animation: borderAppear 2s forwards;
            //     /* Start after the ripple finishes */
            // }

            // @keyframes borderAppear {
            //     0% {
            //         opacity: 0;
            //     }

            //     100% {
            //         opacity: 1;
            //     }
            // }


        }


        .react-calendar__navigation {
            margin-bottom: 0;
        }

        .react-calendar__month-view__weekdays abbr[title] {
            text-decoration: none !important;

        }

        .highlight-present {
            background-color: var(--secondary-color) !important;
            color: var(--secondary-background-color);

        }

        .highlight-absent {
            background-color: var(--over-due-pie_chart-color) !important;
            color: var(--secondary-background-color);
        }


        .highlight-NA {
            background-color: var(--primary-border-color) !important;
            color: var(--secondary-background-color);
        }


        .highlight-comment {
            position: relative;
        }

        .highlight-comment::after {
            content: '';
            position: absolute;
            bottom: 0.2vw;
            left: 50%;
            transform: translateX(-50%);
            width: 0.5vw;
            height: 0.5vw;
            background-color: var(--primary-color);
            border-radius: 50%;
        }
    }

    .calendar-legends {
        display: flex;

        justify-content: flex-start;
        align-items: center;
        margin-top: 5vw;
        flex-wrap: wrap;

        .legend-item {
            display: flex;
            align-items: center;
            margin-right: 4vw;


            h3 {
                margin-left: 1.5vw;
                font-size: 4.5vw;
                font-weight: 500;
            }

            .legend-box {
                width: 5vw;
                height: 5vw;
                border-radius: 1vw;
            }
        }

        .present-container .present-box {
            background-color: var(--secondary-color); // Green for present
        }

        .absent-container .absent-box {
            background-color: var(--over-due-pie_chart-color); // Red for absent
        }

        .present-container .active-box {
            background-color: var(--primary-color);
        }

        .absent-container .current-date-box {
            background-color: var(--pending-pie_chart-color); // Red for absent
        }
    }

    .student-attendance-record-submit-button-container {
        display: flex;
        justify-content: end;
        margin-top: 1vw;

        .student-attendance-record-submit-button {
            width: 25vw;
        }
    }

    .student-attendance-record-right-container {

        .student-attendance-record-edit-button-container {
            display: flex;
            justify-content: end;
        }

        .student-attendance-record-edit-button {
            width: 25vw;
        }

        .student-attendance-record-date-status-comment-container {

            .after_submit_para {
                font-size: 4.5vw;
                margin-top: 1vw;
            }

            .student-attendance-record-status-container {
                display: flex;
                width: 100%;
                margin-top: 0.5vw;
                font-size: 4.5vw;

                h4 {
                    font-weight: 500;
                }

                .status-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {
                        width: auto;
                        margin-left: 4vw;
                        font-size: 4.5vw;
                    }
                }
            }

            .student-attendance-record-comment-container {
                margin-top: 1vw;
            }

        }

        table {
            width: 100%;
            border-collapse: collapse;
            background-color: var(--secondary-background-color);
            margin-top: 2vw;
            overflow: auto;
            display: flow;
        }

        th,
        td {
            border: 0.3vw solid var(--primary-text-color);
            padding: 2vw;
            text-align: center;
            font-size: 4.5vw;
        }


        th {
            background-color: var(--secondary-background-color);
        }

    }

    .student-attendance-record-back-button-container {
        display: none;


        .student-attendance-record-edit-button {
            width: 20vw;
        }
    }

    .student-attendance-record-back-button-container-mobile {
        display: flex;
        // justify-content: end;
        margin-top: 1vw;
    }

}